<template>
  <div>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1">
      <user-mescan-tooltip type="gesamtuebersicht"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Gesamtübersicht</v-card-title>
      <v-card-subtitle>in kg</v-card-subtitle>

      <apexchart type="donut" height="200" :options="completeOverViewDonut.options" :series="completeOverViewDonut.series"></apexchart>

      <!-- Auflistung unter dem Chart -->
      <v-card-text v-if="completeOverViewDonut.weight">
        <div class="mass-breakdown">
          <div class="group">
            <span class="font-weight-bold">Fettfreie Masse: {{ fatFreeTotalPercent }}%</span>
            <ul>
              <li v-for="item in fatFreeComponents" :key="item.label">
                <span class="color-square" :style="{ backgroundColor: item.color }"></span>
                {{ item.label }}: {{ item.percent }}%
              </li>
            </ul>
          </div>
          <!-- Fettmasse -->
          <div class="group">
            <span class="px-0 font-weight-bold">Fettmasse: {{ fatMassTotalPercent }}%</span>
            <ul>
              <li v-for="item in fatMassComponents" :key="item.label">
                <span class="color-square" :style="{ backgroundColor: item.color }"></span>
                {{ item.label }}: {{ item.percent }}%
              </li>
            </ul>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl mt-2 elevation-1">
      <user-mescan-tooltip type="muskelverteilung"></user-mescan-tooltip>
      <v-btn large icon @click="totalMuscleShowPercentage = !totalMuscleShowPercentage" absolute style="top: 55px; right: 15px"
        ><v-icon>{{ totalMuscleShowPercentage ? "mdi-weight-kilogram" : "mdi-percent-circle" }}</v-icon></v-btn
      >
      <v-card-title class="font-weight-bold">Muskelmasse</v-card-title>
      <v-card-subtitle>{{ totalMuscleShowPercentage ? "in %" : "in kg" }}</v-card-subtitle>

      <object
        :key="response.date"
        class="interactive-human-body__svg"
        v-if="response && response.data"
        @load="frontInit"
        id="front"
        type="image/svg+xml"
        data="/img/einmalig/meScanBody.svg"></object>

      <v-row v-for="(item, idx) in ratingItems" :key="'rating-item' + idx" no-gutters class="mx-2 justify-space-between">
        <v-col cols="auto">
          <span class="font-weight-bold">{{ item.title }}</span>
        </v-col>
        <v-col cols="auto">
          <span class="font-weight-bold">{{ totalMuscleShowPercentage ? item.percentageValue + "%" : item.value + " kg" }}</span>
        </v-col>
        <user-mescan-data-index-scala
          :biggerTheBetter="true"
          :percentage="totalMuscleShowPercentage"
          style="width: 100%"
          :colors="colors"
          :scaleData="item.rating"
          weight="50"></user-mescan-data-index-scala>
      </v-row>
    </v-card>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl mt-2 elevation-1">
      <user-mescan-tooltip type="fettmasse"></user-mescan-tooltip>
      <v-btn large icon @click="fatDonutShowPercentage = !fatDonutShowPercentage" absolute style="top: 55px; right: 15px"
        ><v-icon>{{ fatDonutShowPercentage ? "mdi-weight-kilogram" : "mdi-percent-circle" }}</v-icon></v-btn
      >
      <v-card-title class="font-weight-bold">Fettmasse</v-card-title>
      <v-card-subtitle>{{ fatDonutShowPercentage ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="donut" height="200" :options="fatDonut.options" :series="fatDonut.series"></apexchart>
      <v-card-text>
        <div class="mass-breakdown">
          <div class="group">
            <span class="px-0 font-weight-bold">Fettmasse: {{ fatDonutShowPercentage ? fatMassTotalPercent + " %" : fatMassTotal + " kg" }}</span>
            <ul>
              <li v-for="item in fatMassComponentsFatDonut" :key="item.label">
                <span class="color-square" :style="{ backgroundColor: item.color }"></span>
                <div class="d-flex justify-space-between" style="width: 100%">
                  <span> {{ item.label }}: </span>
                  <span> {{ item.percent }} {{ fatDonutShowPercentage ? "%" : "kg" }} </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </v-card-text>
      <user-mescan-data-index-scala
        v-if="response && response.rating"
        style="width: 100%"
        :colors="colors"
        :percentage="fatDonutShowPercentage"
        :scaleData="response.rating.fatTotal"
        weight="50"></user-mescan-data-index-scala>
    </v-card>

    <v-card v-if="response && response.rating" outlined class="d-flex flex-column pa-1 rounded-xl mt-2 elevation-1">
      <user-mescan-tooltip type="viszerales_fett"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Viszerales Fett</v-card-title>
      <v-card-text class="ma-0 pb-0">
        <div class="mass-breakdown">
          <div class="group">
            <li>
              <span class="color-square" :style="{ backgroundColor: colors[response.rating.visceralFat.index] }"></span>
              <div class="d-flex justify-space-between" style="width: 100%">
                <span> Viszerales Fett: </span>
                <span> {{ response.data.fat.absolute.visceralFat }} kg </span>
              </div>
            </li>
          </div>
        </div>
      </v-card-text>
      <user-mescan-data-index-scala
        style="width: 100%"
        :colors="colors"
        :scaleData="response.rating.visceralFat"
        weight="50"></user-mescan-data-index-scala>
    </v-card>
    <v-card v-if="response && response.rating" outlined class="d-flex flex-column pa-1 rounded-xl mt-2 elevation-1">
      <user-mescan-tooltip type="wasser"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Wasser</v-card-title>
      <user-mescan-data-water-drop
        v-if="response && response.data"
        :waterRating="response.rating.waterTotal"
        :waterValue="response.data.water.absolute.total"></user-mescan-data-water-drop>

      <v-card-text class="ma-0 pb-0">
        <div class="mass-breakdown">
          <div class="group">
            <li>
              <span class="color-square" :style="{ backgroundColor: '#2196F3' }"></span>
              <div class="d-flex justify-space-between" style="width: 100%">
                <span> Wasser: </span>
                <span> {{ response.data.water.absolute.total }} kg </span>
              </div>
            </li>
          </div>
        </div>
      </v-card-text>
      <user-mescan-data-index-scala
        style="width: 100%"
        :colors="colors"
        biggerTheBetter
        :scaleData="response.rating.waterTotal"
        weight="50"></user-mescan-data-index-scala>
    </v-card>
  </div>
</template>

<script>
import api from "@/api"
import UserMescanDataIndexScala from "@/views/user/components/UserMescanDataIndexScala.vue"
import UserMescanDataWaterDrop from "@/views/user/components/UserMescanDataWaterDrop.vue"
import UserMescanTooltip from "@/views/user/components/UserMescanTooltip.vue"

export default {
  components: {
    UserMescanDataIndexScala,
    UserMescanDataWaterDrop,
    UserMescanTooltip,
  },
  data() {
    return {
      fatDonutShowPercentage: false,
      totalMuscleShowPercentage: false,
      colors: ["#ff4d4d", "#ff6666", "#ff9966", "#ffd900", "#66cc66", "#72ab75", "#3c8c40"],
    }
  },
  props: {
    response: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
  methods: {
    frontInit() {
      const frontSVG = document.getElementById("front").getSVGDocument()
      this.ratingItems.forEach((item) => {
        item.humanBodyPart.forEach((filter) => {
          let allElements = [...frontSVG.getElementsByClassName(filter)]
          for (let element in allElements) {
            allElements[element].setAttribute("fill", this.colors[item.rating.index])
          }
        })
      })
    },
  },
  computed: {
    ratingItems() {
      if (!this.response.data) return []
      return [
        {
          title: "Gesamt",
          humanBodyPart: ["skip"],
          value: this.response.data.muscle.absolute.total,
          percentageValue: this.response.data.muscle.percentage.total,
          rating: this.response.rating.muscleTotal,
        },
        {
          title: "Torso",
          humanBodyPart: ["abs", "chest", "belly"],
          value: this.response.data.muscle.absolute.trunk,
          percentageValue: this.response.data.muscle.percentage.trunk,
          rating: this.response.rating.muscleTrunk,
        },
        {
          title: "Arm R",
          humanBodyPart: ["right-arm"],
          value: this.response.data.muscle.absolute.rightArm,
          percentageValue: this.response.data.muscle.percentage.rightArm,
          rating: this.response.rating.muscleRightArm,
        },
        {
          title: "Arm L",
          humanBodyPart: ["left-arm"],
          value: this.response.data.muscle.absolute.leftArm,
          percentageValue: this.response.data.muscle.percentage.leftArm,
          rating: this.response.rating.muscleLeftArm,
        },
        {
          title: "Bein R",
          humanBodyPart: ["right-leg"],
          value: this.response.data.muscle.absolute.rightLeg,
          percentageValue: this.response.data.muscle.percentage.rightLeg,
          rating: this.response.rating.muscleRightLeg,
        },
        {
          title: "Bein L",
          humanBodyPart: ["left-leg"],
          value: this.response.data.muscle.absolute.leftLeg,
          percentageValue: this.response.data.muscle.percentage.leftLeg,
          rating: this.response.rating.muscleLeftLeg,
        },
      ]
    },
    completeOverViewDonut() {
      // Sicherstellen, dass wir valide Daten haben
      if (!this.response.data) {
        return { options: {}, series: [] }
      }
      // Berechnungen für fettfreie und Fettmasse
      const fatFree = {
        water: this.response.data.water.absolute.total,
        muscleProtein: this.response.data.muscle.absolute.muscleProtein,
      }

      const fatMass = {
        essentialFat: this.response.data.fat.absolute.essentialFat,
        fatReserve: this.response.data.fat.absolute.fatReserve,
        excessiveFat: this.response.data.fat.absolute.excessiveFat,
      }

      // Passives Gewebe = Gesamtgewicht minus aller anderen Massen
      let passiveTissue =
        this.response.userInfo.weight - fatFree.water - fatFree.muscleProtein - fatMass.essentialFat - fatMass.fatReserve - fatMass.excessiveFat
      passiveTissue = Math.round(passiveTissue * 1000) / 1000

      // Konfiguration des ApexCharts
      const options = {
        chart: {
          type: "donut",
        },
        // Farben für die einzelnen Segmente – die ersten drei gehören zur fettfreien Masse,
        // die nächsten drei zur Fettmasse
        colors: [
          "#2196F3", // Wasser (kräftiges Blau)
          "#8D6E63", // Muskelprotein (dezentes Braun/Beige)
          "#FCCABF", // Passives Gewebe (helles Grau-Beige)
          "#FFA726", // Essentielles Fett (kräftiges Orange)
          "#FFCC80", // Fettreserve (helleres Orange)
          "#FFE082", // Ueberschuessiges Fett (Pastell-Gelb)
          // Optional weitere Farbe:
          // "#4CAF50" // Alternativer Gruenton
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                name: {
                  fontSize: "12px",
                },
                show: true,
                value: {
                  fontSize: "20px",
                  color: "white",

                  formatter: (val) => `${val}kg`,
                },
                total: {
                  show: true,
                  color: "white",

                  label: "Körpergewicht",
                  formatter: (w) => this.response.userInfo.weight + "kg",
                },
              },
            },
          },
        },
        labels: ["Wasser", "Muskelprotein", "Passives Gewebe", "Essentielles Fett", "Fettreserve", "Überschüssiges Fett"],
      }

      const series = [fatFree.water, fatFree.muscleProtein, passiveTissue, fatMass.essentialFat, fatMass.fatReserve, fatMass.excessiveFat]

      return {
        options,
        series,
        fatFree,
        fatMass,
        passiveTissue,
        weight: this.response.userInfo.weight,
      }
    },
    fatDonut() {
      if (!this.response.data) {
        return { options: {}, series: [] }
      }

      const fatMass = {
        essentialFat: this.response.data.fat[this.fatDonutShowPercentage ? "percentage" : "absolute"].essentialFat,
        fatReserve: this.response.data.fat[this.fatDonutShowPercentage ? "percentage" : "absolute"].fatReserve,
        excessiveFat: this.response.data.fat[this.fatDonutShowPercentage ? "percentage" : "absolute"].excessiveFat,
      }

      // Konfiguration des ApexCharts
      const options = {
        chart: {
          type: "donut",
        },
        // Farben für die einzelnen Segmente – die ersten drei gehören zur fettfreien Masse,
        // die nächsten drei zur Fettmasse
        colors: [
          "#FFA726", // Essentielles Fett (kräftiges Orange)
          "#FFCC80", // Fettreserve (helleres Orange)
          "#FFE082", // Ueberschuessiges Fett (Pastell-Gelb)
          // Optional: "#4CAF50" als zusätzlicher Grünton, falls benötigt
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                name: {
                  fontSize: "12px",
                },
                color: "white",

                show: true,
                value: {
                  color: "white",

                  fontSize: "20px",
                  formatter: (val) => `${val}` + (this.fatDonutShowPercentage ? "%" : "kg"),
                },
                total: {
                  color: "white",
                  show: true,
                  label: "Gesamt",
                  formatter: (w) =>
                    this.response.data.fat[this.fatDonutShowPercentage ? "percentage" : "absolute"].total +
                    (this.fatDonutShowPercentage ? "%" : "kg"),
                },
              },
            },
          },
        },
        labels: ["Essentielles Fett", "Fettreserve", "Überschüssiges Fett"],
      }

      const series = [fatMass.essentialFat, fatMass.fatReserve, fatMass.excessiveFat]

      return {
        options,
        series,
        fatMass,
      }
    },

    // Gesamtwerte der fettfreien und Fettmasse
    fatFreeTotal() {
      const data = this.completeOverViewDonut
      if (!data.weight) return 0
      return data.fatFree.water + data.fatFree.muscleProtein + data.passiveTissue
    },
    fatMassTotal() {
      const data = this.completeOverViewDonut
      if (!data.weight) return 0
      return data.fatMass.essentialFat + data.fatMass.fatReserve + data.fatMass.excessiveFat
    },

    // Prozentuale Anteile (auf 1 Dezimalstelle gerundet)
    fatFreeTotalPercent() {
      const data = this.completeOverViewDonut
      if (!data.weight) return "0.0"
      return ((this.fatFreeTotal / data.weight) * 100).toFixed(1)
    },
    fatMassTotalPercent() {
      const data = this.completeOverViewDonut
      if (!data.weight) return "0.0"
      return ((this.fatMassTotal / data.weight) * 100).toFixed(1)
    },

    // Auflistung der fettfreien Komponenten mit Prozentwerten und zugehöriger Farbe
    fatFreeComponents() {
      const data = this.completeOverViewDonut
      if (!data.weight) return []
      const comps = [
        { label: "Wasser", value: data.fatFree.water, color: "#2196F3" },
        { label: "Muskelprotein", value: data.fatFree.muscleProtein, color: "#8D6E63" },
        { label: "Passives Gewebe", value: data.passiveTissue, color: "#FCCABF" },
      ]
      return comps.map((item) => ({
        label: item.label,
        percent: ((item.value / data.weight) * 100).toFixed(1),
        color: item.color,
      }))
    },
    // Auflistung der Fettmassenkomponenten
    fatMassComponents() {
      const data = this.completeOverViewDonut
      if (!data.weight) return []
      const comps = [
        { label: "Essentielles Fett", value: data.fatMass.essentialFat, color: "#FFA726" },
        { label: "Fettreserve", value: data.fatMass.fatReserve, color: "#FFCC80" },
        { label: "Überschüssiges Fett", value: data.fatMass.excessiveFat, color: "#FFE082" },
      ]
      return comps.map((item) => ({
        label: item.label,
        percent: ((item.value / data.weight) * 100).toFixed(1),
        color: item.color,
      }))
    },
    fatMassComponentsFatDonut() {
      const data = this.fatDonut
      if (!data.fatMass) return []
      const comps = [
        { label: "Essentielles Fett", value: data.fatMass.essentialFat, color: "#FFA726" },
        { label: "Fettreserve", value: data.fatMass.fatReserve, color: "#FFCC80" },
        { label: "Überschüssiges Fett", value: data.fatMass.excessiveFat, color: "#FFE082" },
      ]
      return comps.map((item) => ({
        label: item.label,
        percent: item.value,
        color: item.color,
      }))
    },
  },
}
</script>

<style scoped>
.mass-breakdown .group {
  margin-bottom: 1rem;
}
.mass-breakdown ul {
  list-style: none;
  padding: 0;
}
.mass-breakdown li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.color-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.interactive-human-body__svg {
  width: 33%; /* oder ein Prozentwert, z.B. 50% */
  height: 260px; /* behält das Seitenverhältnis bei */
  margin: auto;
}
</style>
