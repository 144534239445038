<template>
  <div class="water-drop align-self-center">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <!-- Umriss des Tropfens mit dem neuen Pfad -->
      <path d="M19,14A7,7,0,0,1,5,14C5,8,12,3,12,3S19,8,19,14Z" fill="none" stroke="#000" stroke-width="1" />
      <!-- ClipPath basierend auf dem neuen Tropfenpfad -->
      <clipPath id="dropClip">
        <path d="M19,14A7,7,0,0,1,5,14C5,8,12,3,12,3S19,8,19,14Z" />
      </clipPath>
      <!-- Das Füllrechteck, das von unten aufgefüllt wird -->
      <rect x="0" :y="fillY" width="24" :height="fillHeight" fill="#2196F3" clip-path="url(#dropClip)" />
      <!-- Anzeige des Wasserwerts -->
      <text x="12" y="16" text-anchor="middle" fill="#fff" font-size="3">{{ waterValue }} kg</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "WaterDrop",
  props: {
    waterValue: {
      type: Number,
      required: true,
    },
    waterRating: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Berechne den Füllungsanteil anhand des Index und der Gesamtzahl der Bereiche
    fillFraction() {
      const totalSteps = this.waterRating.ranges.absolute.length
      return Math.min(this.waterRating.index / totalSteps, 1)
    },
    // Höhe des Füllrechtecks (bei viewBox-Höhe 24)
    fillHeight() {
      return this.fillFraction * 24
    },
    // y-Position des Rechtecks (Füllung erfolgt von unten)
    fillY() {
      return 24 - this.fillHeight
    },
  },
}
</script>

<style scoped>
.water-drop {
  width: 100px;
  height: 100px;
  animation: ue-abe 2s linear infinite;
}
@keyframes ue-abe {
  0% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
