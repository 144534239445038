<template>
  <div cols="12">
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1">
      <user-mescan-tooltip type="herzfrequenz"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Herzfrequenz</v-card-title>
      <v-card-subtitle>in Schlägen pro Minute (bpm)</v-card-subtitle>

      <!-- Pulsierendes Herz mit Zahl -->
      <div class="heart-rate-container">
        <svg viewBox="0 0 32 29.6" class="heart-rate-svg pulsating-heart">
          <path
            d="M23.6,0c-3.4,0-6.4,1.8-8,4.6C13.8,1.8,10.8,0,7.4,0C3.3,0,0,3.3,0,7.4
                     c0,5.9,6.4,10.5,16,19.2c9.6-8.7,16-13.3,16-19.2C32,3.3,28.7,0,24.6,0H23.6z"
            fill="red" />
        </svg>
        <div class="heart-rate-text">
          <number :from="0" :to="response.data.heartRate.absolute.avgHeartRate" class="heart-rate-number"></number>
          <div class="heart-rate-unit">bpm</div>
        </div>
      </div>

      <user-mescan-data-index-scala
        style="width: 100%"
        :colors="colors"
        bpm
        :scaleData="response.rating.heartRate"
        weight="50"></user-mescan-data-index-scala>
    </v-card>

    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1 mt-2">
      <user-mescan-tooltip type="hrv"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">HRV-Trainingsbereitschaft</v-card-title>
      <div class="heart-container">
        <svg viewBox="0 0 32 29.6" class="heart-svg">
          <path
            d="M23.6,0c-3.4,0-6.4,1.8-8,4.6C13.8,1.8,10.8,0,7.4,0C3.3,0,0,3.3,0,7.4
                     c0,5.9,6.4,10.5,16,19.2c9.6-8.7,16-13.3,16-19.2C32,3.3,28.7,0,24.6,0H23.6z"
            fill="red" />
        </svg>
        <div class="heart-text">
          <div class="heart-number">{{ response.data.workoutReadiness.absolute.workoutReadiness }}</div>
          <div class="heart-unit">ms</div>
        </div>
      </div>

      <user-mescan-data-index-scala
        style="width: 100%"
        :colors="colors"
        useKeys
        :scaleData="response.rating.workoutReadiness"
        weight="50"></user-mescan-data-index-scala>
    </v-card>
  </div>
</template>

<script>
import api from "@/api"
import UserMescanDataIndexScala from "@/views/user/components/UserMescanDataIndexScala.vue"
import UserMescanTooltip from "@/views/user/components/UserMescanTooltip.vue"

export default {
  components: {
    UserMescanDataIndexScala,
    UserMescanTooltip,
  },
  data() {
    return {
      colors: ["#ff4d4d", "#ff6666", "#ff9966", "#ffd900", "#66cc66", "#72ab75", "#3c8c40"],
    }
  },
  props: {
    user: {
      type: Object,
    },
    response: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.mass-breakdown .group {
  margin-bottom: 1rem;
}
.mass-breakdown ul {
  list-style: none;
  padding: 0;
}
.mass-breakdown li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.color-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.interactive-human-body__svg {
  width: 33%;
  height: auto;
  margin: auto;
}

/* Styling für das pulsierende Herz der Herzfrequenz */
.heart-rate-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 20px auto;
}
.heart-rate-svg {
  width: 100%;
  height: 100%;
}
.heart-rate-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.heart-rate-number {
  font-size: 2em;
  font-weight: bold;
  line-height: 1;
}
.heart-rate-unit {
  font-size: 0.8em;
  line-height: 1;
}

/* Pulsierende Animation */
.pulsating-heart {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Styling für das HRV Herz */
.heart-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
}
.heart-svg {
  width: 100%;
  height: 100%;
}
.heart-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.heart-number {
  font-size: 1.5em;
  line-height: 1;
}
.heart-unit {
  font-size: 0.8em;
  line-height: 1;
}
</style>
