<template>
  <div>
    <!-- Bio Age -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Bio Age</v-card-title>
      <v-card-subtitle>in Jahren</v-card-subtitle>
      <apexchart
        type="line"
        height="300"
        :options="getChartOptions({ markers: { fillColors: bioAgeMarkerColors } })"
        :series="bioAgeSeries"></apexchart>
    </v-card>

    <!-- Zellfitnesswert -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Zellfitnesswert</v-card-title>
      <v-card-subtitle>in °</v-card-subtitle>
      <apexchart
        type="line"
        height="300"
        :options="getChartOptions({ markers: { fillColors: cellFitnessMarkerColors } })"
        :series="cellFitnessSeries"></apexchart>
    </v-card>

    <!-- Sauerstoffsättigung -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Sauerstoffsättigung</v-card-title>
      <v-card-subtitle>in %</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="spo2Series"></apexchart>
    </v-card>

    <!-- Wasserhaushalt -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="waterToggle = !waterToggle" absolute top right>
        <v-icon>{{ waterToggle ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Wasserhaushalt</v-card-title>
      <v-card-subtitle>{{ waterToggle ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="waterSeries"></apexchart>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MultiMeasurementStatistics",
  props: {
    measurements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      waterToggle: false, // false = absolute (kg), true = percentage
      cellFitnessColors: ["#ff4d4d", "#ff6666", "#ff9966", "#ffd900", "#66cc66", "#72ab75", "#3c8c40"],
      // Zentrale Basisoptionen – alle Labels in Weiß, Tooltip dunkel
      baseChartOptions: {
        chart: {
          type: "line",
          zoom: { enabled: false },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
          style: { colors: ["#ffffff"] },
        },
        legend: {
          show: true,
          position: "top",
          labels: { colors: "#ffffff" },
        },
        tooltip: {
          theme: "dark",
        },
      },
    }
  },
  computed: {
    sortedMeasurements() {
      return this.measurements.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    // X-Achsen-Beschriftungen (Datum der Messung)
    categories() {
      return this.sortedMeasurements.map((m) => new Date(m.date).toLocaleDateString())
    },
    // Bio Age Serie (Werte aus response.data.bioAge.absolute.bioAge)
    bioAgeSeries() {
      return [
        {
          name: "Bio Age",
          data: this.sortedMeasurements.map((m) => Math.round(m.data.bioAge.absolute.bioAge * 10) / 10),
        },
      ]
    },
    // Dynamische Marker-Farben für Bio Age basierend auf dem Rating (Index 0 bis 2)
    bioAgeMarkerColors() {
      return this.sortedMeasurements.map((m) => {
        const idx = m.rating.bioAge.index
        const colorArr = ["#ff4d4d", "#ffd900", "#3c8c40"]
        return colorArr[idx] || "#ffffff"
      })
    },
    // Zellfitnesswert Serie (Werte aus response.data.cellFitness.absolute.cellFitnessValue)
    cellFitnessSeries() {
      return [
        {
          name: "Zellfitnesswert",
          data: this.sortedMeasurements.map((m) => m.data.cellFitness.absolute.cellFitnessValue),
        },
      ]
    },
    // Dynamische Marker-Farben für Zellfitness basierend auf dem Rating (Index aus cellFitnessColors)
    cellFitnessMarkerColors() {
      return this.sortedMeasurements.map((m) => {
        const idx = m.rating.cellFitnessValue.index
        return this.cellFitnessColors[idx] || "#ffffff"
      })
    },
    // Sauerstoffsättigung Serie (Werte aus response.data.oxygen.percentage.spo2)
    spo2Series() {
      return [
        {
          name: "Sauerstoffsättigung",
          data: this.sortedMeasurements.map((m) => m.data.oxygen.percentage.spo2),
          color: "#ffd900", // Fester Farbton
        },
      ]
    },
    // Wasserhaushalt Serie: Zwei Series für Extrazelluläres und Intrazelluläres Wasser
    waterSeries() {
      return [
        {
          name: "Extrazelluläres Wasser",
          data: this.sortedMeasurements.map((m) => m.data.water[this.waterToggle ? "percentage" : "absolute"].extraCellular),
          color: "#2196F3",
        },
        {
          name: "Intrazelluläres Wasser",
          data: this.sortedMeasurements.map((m) => m.data.water[this.waterToggle ? "percentage" : "absolute"].intraCellular),
          color: "#3606f5",
        },
      ]
    },
  },
  methods: {
    // Zentrale Options-Konfiguration – hier können zusätzliche (chartspezifische) Optionen übergeben werden
    getChartOptions(extra = {}) {
      return {
        ...this.baseChartOptions,
        ...extra,
        xaxis: {
          categories: this.categories,
          labels: { style: { colors: "#ffffff" } },
        },
        yaxis: {
          labels: { style: { colors: "#ffffff" } },
        },
      }
    },
  },
}
</script>

<style scoped>
.mb-4 {
  margin-bottom: 1rem;
}
</style>
