<template>
  <div class="scale-container align-self-center mb-4">
    <div style="margin: auto" class="scale">
      <v-tooltip :color="color" v-for="(color, idx) in colors" :key="idx" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="scale-segment" :class="{ highlighted: idx === scaleData.index }" :style="{ backgroundColor: color }" v-on="on" v-bind="attrs">
            <!-- Falls gewünscht, kannst du hier einen sichtbaren Inhalt (z.B. &nbsp;) einfügen -->
            &nbsp;
          </div>
        </template>
        <span v-if="useKeys">{{ tooltipTexts[idx] }}</span>
        <span v-else
          >{{ idx + 1 === colors.length ? (biggerTheBetter ? ">" : "<") : idx === 0 ? (biggerTheBetter ? "<" : ">") : ""
          }}{{ tooltipTexts[idx] }}</span
        >
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    useKeys: {
      type: Boolean,
      default: false,
    },
    biggerTheBetter: {
      type: Boolean,
      default: false,
    },
    scaleData: {
      type: Object,
      required: true,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    degree: {
      type: Boolean,
      default: false,
    },
    bpm: {
      type: Boolean,
      default: false,
    },
    years: {
      type: Boolean,
      default: false,
    },
    colors: {},
  },
  computed: {
    tooltipTexts() {
      if (this.useKeys) return ["Keine", "Sehr Niedrig", "Niedrig", "Moderat", "Gut", "Sehr Gut", "Optimal"]
      if (!this.scaleData.ranges) return []
      let percentageAddString = this.years ? "" : this.bpm ? "bpm" : this.percentage ? "%" : this.degree ? "°" : "kg"

      return this.scaleData.ranges[this.percentage ? "percentage" : "absolute"].map((range) => {
        if (range.length === 1) {
          return `${range[0]}` + percentageAddString
        } else if (range.length === 2) {
          return `${range[0]}${percentageAddString} - ${range[1]}${percentageAddString}`
        } else {
          return range.join(", ")
        }
      })
    },
  },
}
</script>

<style scoped>
.scale-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.scale {
  display: flex;
  width: 300px;
  height: 25px;
  border-radius: 5px;
  overflow: hidden;
}
.scale-segment {
  flex: 1;
  height: 15px;
  cursor: pointer;
  margin: auto 1px;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scale-segment:hover {
  transform: scale(1.1);
}
.highlighted {
  transform: scale(1.2, 1.3);
  height: 25px;
}
</style>
