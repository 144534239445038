<template>
  <div>
    <!-- Datum der Messungen anzeigen -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Verglichene Messungen</v-card-title>
      <v-card-text style="display: flex; justify-content: space-around">
        <span> {{ formattedOldMeasurementDate }}</span>
        <v-icon>mdi-arrow-right</v-icon>
        <span> {{ formattedNewMeasurementDate }}</span>
      </v-card-text>
    </v-card>

    <!-- Herzfrequenz Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Herzfrequenz Vergleich</v-card-title>
      <v-card-subtitle>in Schlägen pro Minute (bpm)</v-card-subtitle>
      <v-card-text>
        <div class="comparison-row">
          <span class="comparison-label">Herzfrequenz</span>
          <span class="comparison-value">{{ heartRateComparison.value1 }} bpm</span>
          <span class="comparison-diff" :style="{ color: heartRateComparison.diffColor }">
            <v-icon v-if="heartRateComparison.arrowIcon" small>{{ heartRateComparison.arrowIcon }}</v-icon>
            {{ heartRateComparison.percentChange }}%
          </span>
          <span class="comparison-value">{{ heartRateComparison.value2 }} bpm</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- HRV-Trainingsbereitschaft Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">HRV-Trainingsbereitschaft Vergleich</v-card-title>
      <v-card-subtitle>in ms</v-card-subtitle>
      <v-card-text>
        <div class="comparison-row">
          <span class="comparison-label">HRV-Trainingsbereitschaft</span>
          <span class="comparison-value">{{ workoutComparison.value1 }} ms</span>
          <span class="comparison-diff" :style="{ color: workoutComparison.diffColor }">
            <v-icon v-if="workoutComparison.arrowIcon" small>{{ workoutComparison.arrowIcon }}</v-icon>
            {{ workoutComparison.percentChange }}%
          </span>
          <span class="comparison-value">{{ workoutComparison.value2 }} ms</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "HeartComparison",
  props: {
    measurement1: {
      type: Object,
      required: true,
    },
    measurement2: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Vergleicht zwei Werte und berechnet den prozentualen Unterschied.
    // increaseIsGood gibt an, ob eine Zunahme (true) oder Abnahme (false) eine Verbesserung darstellt.
    compareMetric(label, value1, value2, unit, increaseIsGood) {
      // Für den Fall, dass value1 0 ist, geben wir "N/A" zurück
      if (value1 === 0) {
        return {
          label,
          value1: value1.toFixed(1),
          value2: value2.toFixed(1),
          unit,
          percentChange: "N/A",
          arrowIcon: "",
          diffColor: "#ffffff",
        }
      }
      const change = ((value2 - value1) / value1) * 100
      const formatted = Math.abs(change).toFixed(1)
      let arrowIcon = ""
      let diffColor = "#ffffff"
      if (change === 0) {
        arrowIcon = ""
        diffColor = "#ffffff"
      } else {
        // Bei increaseIsGood: positive Veränderung ist Verbesserung; bei false: negative Veränderung ist Verbesserung.
        const isImprovement = increaseIsGood ? change > 0 : change < 0
        if (isImprovement) {
          arrowIcon = "mdi-arrow-up"
          diffColor = "green"
        } else {
          arrowIcon = "mdi-arrow-down"
          diffColor = "red"
        }
      }
      return {
        label,
        value1: value1.toFixed(1),
        value2: value2.toFixed(1),
        unit,
        percentChange: formatted,
        arrowIcon,
        diffColor,
      }
    },
  },
  computed: {
    // Ermittelt anhand des Datums, welche Messung älter ist
    oldMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement1 : this.measurement2
    },
    newMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement2 : this.measurement1
    },
    formattedOldMeasurementDate() {
      return new Date(this.oldMeasurement.date).toLocaleDateString()
    },
    formattedNewMeasurementDate() {
      return new Date(this.newMeasurement.date).toLocaleDateString()
    },
    // Vergleich Herzfrequenz:
    // Wir gehen davon aus, dass ein Rückgang (niedrigerer Wert) besser ist, also increaseIsGood = false.
    heartRateComparison() {
      return this.compareMetric(
        "Herzfrequenz",
        this.oldMeasurement.data.heartRate.absolute.avgHeartRate,
        this.newMeasurement.data.heartRate.absolute.avgHeartRate,
        "bpm",
        false
      )
    },
    // Vergleich HRV-Trainingsbereitschaft:
    // Hier gilt: Ein höherer Wert (mehr Trainingsbereitschaft) ist besser → increaseIsGood = true.
    workoutComparison() {
      return this.compareMetric(
        "HRV-Trainingsbereitschaft",
        this.oldMeasurement.data.workoutReadiness.absolute.workoutReadiness,
        this.newMeasurement.data.workoutReadiness.absolute.workoutReadiness,
        "ms",
        true
      )
    },
  },
}
</script>

<style scoped>
.measurement-dates {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.comparison-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.comparison-label {
  flex: 1;
  font-weight: bold;
}
.comparison-value {
  flex: 1;
  text-align: center;
}
.comparison-diff {
  flex: 1;
  text-align: center;
  font-weight: bold;
}
</style>
