<template>
  <v-card>
    <v-app-bar color="transparent" width="100%" elevation="0" class="justify-space-between py-2">
      <v-col cols="1" class="pl-0">
        <v-btn @click="$emit('close-view')" style="z-index: 10000" icon>
          <v-icon color="grey">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11">
        <v-card-title class="ml-2 font-weight-bold">Krankenkassen Bestätigung</v-card-title>

        <!--
                        <v-img height="50px" @click="$router.push('/')" contain src="/img/roody_logo.png"></v-img>
                 -->
      </v-col>
    </v-app-bar>

    <v-card-text>
      <div v-for="(contractType, index) of contractTimespans" :key="'contractType' + index">
        <v-row no-gutters>
          <v-card-title>{{ contractType[0].title }}</v-card-title>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" v-for="(contract, index) of contractType" :key="'contract' + index">
            <v-card class="mb-4 mx-2">
              <v-row no-gutters>
                <v-col cols="10">
                  <v-card-title>
                    {{ contractType.length - index }}. Laufzeit ({{ $moment(contract.start, "DD.MM.YYYY").format("YYYY") }})
                  </v-card-title>
                  <v-card-subtitle> von {{ contract.start }} bis {{ contract.end }} </v-card-subtitle>
                </v-col>
                <v-col cols="2" class="align-content-center">
                  <v-btn @click="requestHealthcareFile(contract)" icon large :color="getColorForRequestState(contract.request)">
                    <v-icon>{{ getIconForRequestState(contract.request) }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-dialog v-model="unlockDialog" persistent max-width="600px">
      <v-card>
        <v-card-title v-if="unlockDialog" class="text-h5">{{ unlockDialogTitle }}</v-card-title>
        <v-card-text v-if="unlockDialog">
          {{ unlockDialogText }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="unlockDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="unlockDialog && unlockDialogBtnText"
            color="green darken-1"
            text
            @click="
              unlockDialog = false
              sendUnlockRequest()
            ">
            {{ unlockDialogBtnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showWaitingDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Anfrage gesendet</v-card-title>
        <v-card-text> Deine Anfrage wurde erfolgreich gesendet. Wir werden die Freischaltung so schnell wie möglich durchführen. </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="showWaitingDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pdfDialog" persistent max-width="600px">
      <v-card v-if="selectedContract">
        <v-card-title style="background-color: var(--v-primary)" class="text-h5 px-5 mb-2 white--text"> Weitere Informationen </v-card-title>
        <v-row class="px-5" no-gutters v-if="!showSignature">
          <v-col class="pb-0 pt-3" cols="12">
            <v-text-field label="Name der Krankenversicherung" type="text" class="my-0 py-0" v-model="customerInfos.healthcareName"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field label="Zusatzversicherung" type="text" class="my-0 py-0" v-model="customerInfos.additionalInsurance"></v-text-field>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-text-field label="Versichertennummer" class="my-0 py-0" v-model="customerInfos.insuranceNumber"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field label="Vorname" class="my-0 py-0 mr-1" v-model="customerInfos.firstName"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field label="Nachname" class="my-0 py-0" v-model="customerInfos.lastName"></v-text-field>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-text-field label="Strasse" class="my-0 py-0" v-model="customerInfos.street"></v-text-field>
          </v-col>

          <v-col class="pt-0" cols="4">
            <v-text-field type="number" label="PLZ" class="my-0 py-0 mr-1" v-model="customerInfos.zip"></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="8">
            <v-text-field label="Stadt" class="my-0 py-0" v-model="customerInfos.city"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-5" v-else>
          <v-col cols="12"><v-card-subtitle>Unterschrift</v-card-subtitle></v-col>
          <v-col cols="12">
            <signature-pad
              class="mx-auto"
              :signature-data="customerInfos.signature"
              @resetDrawing="resetSignature"
              @saveSignature="saveSignature"
              ref="signature"></signature-pad>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn v-if="!showSignature" color="red darken-1" text @click="pdfDialog = false">Abbrechen</v-btn>
          <v-btn v-else color="red darken-1" text @click="showSignature = false">Zurück</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="showSignature" color="green darken-1" text @click="downloadPDF()">Herunterladen</v-btn>
          <v-btn v-else color="green darken-1" text @click="showSignature = true">Weiter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/api"
import signaturePad from "@/components/signaturePad/signaturePad.vue"

export default {
  components: {
    signaturePad,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      pdfDialog: false,
      showSignature: false,
      customerInfos: null,
      loadingTACContract: true,
      error: false,
      contract: null,
      allExistingContracts: null,
      fixTermTimesMissingBefore: [],
      fixTermTimesMissingAfter: [],
      loadingTACContract: false,
      contractEndDate: null,
      allIdlePeriods: [],
      contractStartDate: null,
      contractInformations: null,
      unlockDialog: false,
      selectedContract: null,
      showWaitingDialog: false,
      existingRequests: [],
    }
  },
  created() {
    this.init()
    this.fetchContracts()
  },
  methods: {
    async downloadPDF() {
      const { firstName, lastName, street, city, zip, insuranceNumber, additionalInsurance, healthcareName } = this.customerInfos

      // Einfache Validierungsbedingungen
      if (!firstName || !lastName || !street || !city || !zip || !insuranceNumber || !healthcareName) {
        this.showSignature = false
        this.$toast.error("Bitte füllen Sie alle erforderlichen Felder aus.")
        return
      }
      this.$refs.signature.saveSignature()
      let contractData = JSON.parse(JSON.stringify(this.selectedContract.request))
      contractData.membershipName = contractData.contract.title
      contractData.startDate = contractData.contract.start
      contractData.endDate = contractData.contract.end

      let data = {
        contract: contractData,
        customerInfos: this.customerInfos,
      }

      try {
        this.$toast.success("PDF wird erstellt")
        console.log(data)
        await api.createHealthcarePDF(data)
        this.pdfDialog = false
      } catch (e) {
        this.$toast.error("Fehler beim herunterladen, bitte versuche es erneut")
      }
    },
    resetSignature() {
      this.customerInfos.signature = null
    },
    saveSignature(e) {
      this.customerInfos.signature = e
    },
    getColorForRequestState(request) {
      if (!request) return "red"
      return request.status === "pending" ? "blue" : "primary"
    },
    getIconForRequestState(request) {
      if (!request) return "mdi-file-lock"
      return request.status === "pending" ? "mdi-file-clock" : "mdi-file-download"
    },
    closeForm() {
      this.$emit("closeForm")
    },
    async requestHealthcareFile(contract) {
      if (contract.request && contract.request.status === "accepted") {
        this.selectedContract = contract
        this.pdfDialog = true
        return
      }

      if (this.$moment().isBefore(this.$moment(contract.start, "DD.MM.YYYY").subtract(40, "days")))
        return alert("Diese Mitgliedschaft ist noch nicht aktiv, bitte warte bis 40 Tage vor dem Startdatum.")

      let transactions = []
      try {
        transactions = await api.fetchAccountTransactionsForIDAdminTESTING(
          contract.Accounts.Account.find((acc) => acc.type === "MEMBERSHIP").AccountID.UniqueID[0].value,
          this.$moment("2000-01-01", "YYYY-MM-DD").format(),
          this.$moment().add(4, "years").format()
        )
      } catch (e) {
        console.log(e)
        return alert(
          "Wir konnten leider keine Zahlungen für deine Mitgliedschaft finden. Falls dieses Problem trotz Zahlung auftritt, wende dich bitte an das Personal. "
        )
      }

      transactions =
        transactions.AccountTransactions.AccountTransactions.filter((transaction) => {
          if (!transaction.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange) return false

          let transactionDate = transaction.TransactionDate

          if (contract.originalStart) {
            return (
              this.$moment(transactionDate).isSameOrAfter(this.$moment(contract.originalStart, "YYYY-MM-DD")) &&
              this.$moment(transactionDate).isSameOrBefore(this.$moment(contract.end, "DD.MM.YYYY")) &&
              transaction.type !== "IDLE_PERIOD"
            )
          }

          return (
            this.$moment(transactionDate).isSameOrAfter(this.$moment(contract.start, "DD.MM.YYYY")) &&
            this.$moment(transactionDate).isSameOrBefore(this.$moment(contract.end, "DD.MM.YYYY")) &&
            transaction.type !== "IDLE_PERIOD"
          )
        }) || []
      if (transactions.find((trans) => trans.State === "OPEN")) {
        return alert("Es gibt noch offene Zahlungen für diese Mitgliedschaft. Bitte begleiche diese zuerst.")
      }

      contract.transactions = transactions.map((trans) => {
        return {
          start: trans.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange.Start,
          end: trans.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange.End,
          cost: trans.Value,
          state: trans.State,
          type: trans.Type,
          mainType: trans.MainType,
          det: trans.AccountTransactionDetails,
        }
      })
      this.selectedContract = contract
      this.unlockDialog = true
    },
    async fetchContracts() {
      let error = false
      let contract = null
      try {
        this.existingRequests = await api.fetchHealthcareUnlockRequests()
        let allConts = await api.fetchTACAllMemberships()

        this.allExistingContracts = allConts.MemberDetails.MemberDetail
      } catch (e) {
        error = true
        console.log(e)
        alert(
          "Wir konnten leider keine Mitgliedschaft für dich finden. Es kann sein, dass wir deine Mitgliedschaft mit einer anderen E-Mail Adresse hinterlegt haben. Bitte wende dich an das Personal um dies zu prüfen."
        )
        this.$router.push("/user")
      }
      this.loadingTACContract = false
    },
    async init() {
      let customer = await api.getOwnUserForManager()

      let customerEntry = {
        selection: customer._id,
        gender: customer.geschlecht,
        firstName: customer.vorname,
        lastName: customer.nachname,
        email: customer.email,
        phone: customer.telefon,
        birthday: customer.geburtstag,
        street: customer.adresse_1,
        city: customer.stadt,
        zip: customer.plz,
        signature: null,
      }
      this.customerInfos = customerEntry
    },
    bonusCalculatedFromMultipleTimespans(allTimeSpans, timeSpanDays) {
      const totalDays = allTimeSpans.reduce((total, timeSpan) => {
        return total + this.daysInTimeSpan(timeSpan)
      }, 0)

      return (
        Number(totalDays) === Number(timeSpanDays) || Number(totalDays) - 1 === Number(timeSpanDays) || Number(totalDays) + 1 === Number(timeSpanDays)
      )
    },
    daysInTimeSpan(timeSpan) {
      const start = this.$moment(timeSpan.TimeSpan.Start, "YYYY-MM-DD")
      const end = this.$moment(timeSpan.TimeSpan.End, "YYYY-MM-DD")
      return end.diff(start, "days")
    },
    async sendUnlockRequest() {
      try {
        let status = await api.requestHealthcareUnlock({
          contract: this.selectedContract,
        })

        this.unlockDialog = false
        this.fetchContracts()
        this.showWaitingDialog = true
      } catch (e) {
        this.$toast.error("Ein Fehler ist aufgetreten, bitte versuche es erneut")
      }
    },
  },
  computed: {
    unlockDialogTitle() {
      if (this.selectedContract && !this.selectedContract.request) return "Freischaltung beantragen"
      return this.selectedContract.request.status === "pending" ? "Anfrage wird bearbeitet" : "Bestätigung herunterladen"
    },
    unlockDialogBtnText() {
      if (this.selectedContract && !this.selectedContract.request) return "Beantragen"

      return this.selectedContract.request.status === "pending" ? "" : "Herunterladen"
    },
    unlockDialogText() {
      if (this.selectedContract && !this.selectedContract.request)
        return "Wenn du die Bestätigung deiner Krankenkasse benötigst, kannst du diese hier anfordern. Wir prüfen deine Anfrage schnellstmöglich und schalten die Bestätigung frei."
      return this.selectedContract.request.status === "pending"
        ? "Deine Anfrage wird aktuell bearbeitet. Bitte habe etwas Geduld."
        : "Du kannst die Bestätigung herunterladen."
    },
    contractTimespans() {
      if (!this.allExistingContracts?.length) return []

      const splitTimespanIntoYearChunks = (timespan) => {
        const startMoment = this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD")
        const endMoment = this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD")
        const result = []

        let currentStart = startMoment.clone()
        while (currentStart.isBefore(endMoment)) {
          // 1 Jahr draufrechnen, dann 1 Tag abziehen, damit es genau ein Jahresabschnitt ist
          let currentEnd = currentStart.clone().add(1, "year").subtract(1, "day")

          // Falls der Endpunkt hinter dem ursprünglichen Enddatum liegt, begrenzen
          if (currentEnd.isAfter(endMoment)) {
            currentEnd = endMoment.clone()
          }
          result.push({
            ContractID: timespan.ContractID,
            Type: timespan.Type,
            originalTimeSpan: timespan.TimeSpan,
            TimeSpan: {
              Start: currentStart.format("YYYY-MM-DD"),
              End: currentEnd.format("YYYY-MM-DD"),
            },
          })

          // Start des nächsten Abschnitts: einen Tag nach currentEnd
          currentStart = currentEnd.clone().add(1, "day")
        }

        result.forEach((ts) => {
          ts.yearCount = result.length
        })

        return result
      }

      let allContracts = []

      for (let contract of this.allExistingContracts) {
        console.log(contract, this.existingRequests)

        // Original: zusammenführen von fixTermTimesMissingBefore / After
        let timeSpans = contract.ContractTermTimes.ContractTermTime
        timeSpans = this.fixTermTimesMissingBefore.concat(timeSpans, this.fixTermTimesMissingAfter)

        // 1) ALLE Timespans auf max. 1 Jahr splitten
        let splittedTimeSpans = []
        timeSpans.forEach((ts) => {
          const start = this.$moment(ts.TimeSpan.Start, "YYYY-MM-DD")
          const end = this.$moment(ts.TimeSpan.End, "YYYY-MM-DD")

          // Falls länger als 1 Jahr -> splitten
          if (end.diff(start, "years", true) > 1) {
            splittedTimeSpans = splittedTimeSpans.concat(splitTimespanIntoYearChunks(ts))
          } else {
            splittedTimeSpans.push(ts)
          }
        })

        // Jetzt haben wir in splittedTimeSpans nur noch Abschnitte von max. 1 Jahr
        timeSpans = splittedTimeSpans

        // Deine bestehende Logik
        let activeContractDate = JSON.parse(JSON.stringify(timeSpans))
          .reverse()
          .find((ts) => ts.Type === "DEFAULT" && !this.$moment(ts.TimeSpan.Start, "YYYY-MM-DD").isAfter(this.$moment(), "day")).TimeSpan.Start

        let contracts = []
        let newContract = null

        // "IDLEPERIOD"-Timespans herausfiltern
        let idlePeriodTimeSpans = timeSpans.filter((timespan) => timespan.Type === "IDLEPERIOD")

        // Verarbeiten der (ggf. gesplitteten) Timespans
        timeSpans.forEach((timespan) => {
          let filteredTimeSpans = JSON.parse(JSON.stringify(idlePeriodTimeSpans))
          if (newContract) {
            filteredTimeSpans = filteredTimeSpans.filter((filterTimeSpan) => {
              return this.$moment(filterTimeSpan.TimeSpan.Start).isSameOrAfter(this.$moment(newContract.start, "DD.MM.YYYY"))
            })
          }

          if (timespan.Type === "DEFAULT") {
            if (
              newContract &&
              idlePeriodTimeSpans.length &&
              (idlePeriodTimeSpans.find((idleTimeSpan) => {
                return this.daysInTimeSpan(idleTimeSpan) === this.daysInTimeSpan(timespan)
              }) ||
                this.bonusCalculatedFromMultipleTimespans(filteredTimeSpans, this.daysInTimeSpan(timespan)))
            ) {
              newContract.timeStopBonus.push({
                start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format("DD.MM.YYYY"),
                end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format("DD.MM.YYYY"),
              })
            } else {
              if (newContract !== null) contracts.push(newContract)
              newContract = {
                yearCount: timespan.yearCount,
                originalStart: timespan.originalTimeSpan ? timespan.originalTimeSpan.Start : null,
                ContractID: timespan.ContractID.UniqueID[0].value,
                title: contract.MemberCategoryName,
                Accounts: contract.Accounts,
                start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format("DD.MM.YYYY"),
                end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format("DD.MM.YYYY"),
                bonus: [],
                timeStopBonus: [],
                idlePeriods: [],
                active: activeContractDate === timespan.TimeSpan.Start,
                request: this.existingRequests.find(
                  (req) =>
                    req.contract.ContractID === timespan.ContractID.UniqueID[0].value &&
                    req.contract.start === this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format("DD.MM.YYYY") &&
                    req.contract.end === this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format("DD.MM.YYYY")
                ),
                future: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").isAfter(this.$moment()),
              }
            }
          }

          if (timespan.Type === "BONUSPERIOD") {
            newContract.bonus.push({
              start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format("DD.MM.YYYY"),
              end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format("DD.MM.YYYY"),
            })
          }
        })

        contracts.push(newContract)

        // Zuordnung von "IDLEPERIOD"-Timespans zu den entsprechenden Contracts
        idlePeriodTimeSpans.forEach((timespan) => {
          let foundContract = contracts.find((cont) => {
            let contEndDate = cont.timeStopBonus.length
              ? cont.timeStopBonus[cont.timeStopBonus.length - 1].end
              : cont.bonus.length
              ? cont.bonus[cont.bonus.length - 1].end
              : cont.end

            return this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").isBetween(
              this.$moment(cont.start, "DD.MM.YYYY"),
              this.$moment(contEndDate, "DD.MM.YYYY")
            )
          })

          if (!foundContract) return
          // Falls nötig, hier noch weitere Verarbeitung
        })

        if (!contracts.length && newContract && newContract.title) {
          contracts.push(newContract)
        }

        // Falls kein aktiver Contract gefunden, setze den letzten als aktiv
        if (!contracts.find((cont) => cont.active && !cont.future)) {
          contracts[contracts.length - 1].active = true
        }

        // Zusammenschieben mit allContracts
        if (allContracts.length) {
          let pushed = false
          for (let existingContract of allContracts) {
            // Gleiche "title" => in dasselbe Array pushen
            if (existingContract.find((cont) => cont.title === contracts[0].title)) {
              for (let conti of contracts) {
                existingContract.push(conti)
              }
              pushed = true
            }
          }
          if (!pushed) {
            allContracts.push(contracts)
          }
        } else {
          allContracts.push(contracts)
        }
      }

      // Am Ende drehst du jedes Array um
      return allContracts.map((contract) => {
        return contract.reverse()
      })
    },
  },
}
</script>
