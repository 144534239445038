<template>
  <v-card dark elevation="0" flat>
    <v-app-bar v-if="firstLoad" color="transparent" width="100%" elevation="0" class="justify-space-between py-2">
      <v-col cols="1" class="pl-0">
        <v-btn @click="$emit('close-view')" style="z-index: 10000" icon>
          <v-icon color="grey">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10">
        <v-card-title class="ml-2 font-weight-bold">Timestops</v-card-title>
        <!--
                        <v-img height="50px" @click="$router.push('/')" contain src="/img/roody_logo.png"></v-img>
                 -->
      </v-col>
      <v-col></v-col>
    </v-app-bar>

    <v-row v-if="loading && firstLoad" no-gutters>
      <v-col cols="12">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-else-if="!loadingError && firstLoad" style="max-width: 100%" no-gutters>
      <v-card-text class="pa-4" style="word-break: break-word">
        Willkommen im Timestop-Portal
        <br />
        Hier kannst Du einfach und unkompliziert deinen Timestop beantragen.
        <br />
        1. Einfach die nachfolgenden Schritte abarbeiten
        <br />
        2. Dann wird dein Timestop Antrag intern geprüft
        <br />
        3. Unkompliziert bezahlen
        <br />
        <v-alert type="info">
          Achtung: Erst nach Begleichung der Gebühr, erhältst Du eine Bestätigung per E-Mail, aus der deine Restlaufzeit ersichtlich ist.
        </v-alert>

        <v-expansion-panels class="mt-1">
          <v-expansion-panel>
            <v-expansion-panel-header>1. Interne Prüfung</v-expansion-panel-header>
            <v-expansion-panel-content>
              Mit einer Genehmigung der Geschäftsleitung der well come FIT AG kann die Mitgliedschaft unterbrochen werden. Der Antrag kann jederzeit
              abgelehnt werden.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>2. Dauer des Unterbruchs</v-expansion-panel-header>
            <v-expansion-panel-content>
              Der Unterbruch muss mindestens einen Monat betragen und darf sechs Monate nicht überschreiten.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>3. Unterlagen</v-expansion-panel-header>
            <v-expansion-panel-content>
              Dem Antrag sind Unterlagen zur Prüfung beizulegen. Eine einmalige Gebühr ist bei Vorlage eines ärztlichen Attests, eines Marschbefehls
              oder eines Nachweises für einen Sprachaufenthalt zu entrichten.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>4. Zahlungspflicht während des Unterbruchs</v-expansion-panel-header>
            <v-expansion-panel-content>
              Ein Unterbruch befreit nicht von der Zahlungspflicht sowie der automatischen Abonnementerneuerung und wird nach Bezahlung der
              Unterbruchgebühr der aktuellen Laufzeit angerechnet.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>5. Höhe der anfallenden Gebühr</v-expansion-panel-header>
            <v-expansion-panel-content>
              Die Gebühr beträgt beim Jahresabonnement 1/24 und beim Halbjahresabonnement 1/12 des Mitgliederbeitrages pro Monat Unterbruch.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>6. Maximal zwei Unterbrüche</v-expansion-panel-header>
            <v-expansion-panel-content> Während der Abonnementsdauer sind maximal zwei Unterbrüche möglich. </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-col cols="12" class="text-center">
        <v-btn @click="createNewTimestop()" color="primary">Timestop erfassen</v-btn>
      </v-col>
      <v-col cols="12" class="px-0" v-if="userTimestops.length">
        <v-card-title>Deine existierenden Timestops</v-card-title>
      </v-col>
      <v-row no-gutters style="max-width: 100%" v-if="userTimestops.length">
        <v-col cols="12">
          <v-alert class="ma-auto" type="info" v-if="!userTimestops.length">Keine Timestops gefunden</v-alert>
        </v-col>
        <v-col v-for="timestop of userTimestops" :key="timestop._id" cols="12" class="mt-2">
          <v-card class="secondary darken-2 pa-3 rounded-xl mx-3 my-2" @click="test(timestop)">
            <v-card-title class="h6 font-weight-bold">
              Erstellt am
              {{ $moment(timestop.createdAt).format("DD.MM.YYYY HH:mm") }}
              <v-chip class="ml-2 white--text" :color="timestopStateColorMap[timestop.status]">
                {{ timestopStateMap[timestop.status] }}
                {{
                  timestop.status !== "rejected" && timestop.status !== "open" && timestop.status !== "toLate"
                    ? " " + timestop.tacData.IdlePeriodFee.value + ".-"
                    : ""
                }}
              </v-chip>
            </v-card-title>
            <v-card-subtitle>
              {{
                $moment(timestop.tacData.TimeSpan.Start).format("DD.MM.YYYY") +
                " - " +
                $moment(timestop.tacData.TimeSpan.End).format("DD.MM.YYYY") +
                " (" +
                timestop.tacData.Duration +
                " Tage)"
              }}
              <br />
              <!-- 
                            <span v-if="timestop.status !== 'onHold'">
                                {{ timestop.status === 'accepted' ? 'Kosten:' : 'Vorraussichtliche Kosten:' }}
                                {{ timestop.tacData.IdlePeriodFee.value }}
                            </span>
-->
              <v-alert v-if="timestop.status === 'toLate'" type="error">
                Leider ist die Zahlungsfrist von 7 Tagen für den Timestop abgelaufen. Da wir innerhalb dieser Zeit keine Zahlung erhalten haben,
                konnte der Timestop nicht abgeschlossen werden. Bitte beachte, dass der Timestop nun neu beantragt werden muss.
              </v-alert>
              <v-alert v-if="timestop.status === 'open'" type="info">
                Geschafft. Dein Antrag auf einen Timestop ist bei uns eingegangen. Nach Prüfung, werden wir dich über das weitere Vorgehen
                informieren.
              </v-alert>

              <v-alert v-if="timestop.status === 'onHold'" type="warning">
                Dein Timestop wurde nach der internen Prüfung genehmigt. Sobald du die dafür anfallende Gebühr beglichen hast, werden wir den von dir
                gewünschten Timestop aktivieren. Die Begleichung der Gebühr kannst du direkt in deinem Center oder via Kreditkarte vornehmen.
                <br />
                <br />
                Achtung: Wenn du die Gebühr nicht innerhalb von 7 Tagen zahlst, bleibt dein Antrag auf einen Timestop leider auf der Strecke und du
                musst einen neuen Antrag einreichen.
                <p v-if="timestop.officeNote">
                  {{ timestop.officeNote }}
                </p>
              </v-alert>
              <v-alert v-if="timestop.status === 'payed'" type="info">
                Danke für deine Zahlung. Wir werden den Timestop bei uns hinterlegen. Sobald wir das gemacht haben wird er als abgeschlossen markiert
              </v-alert>
              <v-alert v-if="timestop.status === 'accepted'" type="primary">
                Wir haben den Timestop aktiviert. Die Verlängerung für diesen Zeitraum kannst du in deinem Roody-Account einsehen.
              </v-alert>

              <v-alert v-if="timestop.status === 'rejected'" type="error">
                Deinem Antrag auf einen Timestop können wir aufgrund unserer vertraglichen Bestimmungen leider nicht nachkommen.

                <br />
                <br />
                <span class="font-weight-bold">Grund:</span>
                <br />
                {{ timestop.officeNote }}

                <br />
                <br />
                Besten Dank für Dein Verständnis.
              </v-alert>
              <v-alert type="info" v-if="timestop.status === 'onHold' && false"> Du kannst deine Rechnung direkt an der Theke zahlen. </v-alert>
              <v-alert type="warning" v-if="timestop.status === 'timeRangeChanged'">
                Wir haben deinen Antrag auf einen Timestop geprüft und mussten Anpassungen vornehmen, da der Zeitraum nicht mit dem von dir
                eingereichten Dokument übereinstimmt.
                <br />
                Bitte überprüfe die aktualisierte Version deines Antrags und unterzeichne diesen erneut, um den Prozess fortzusetzen.
                <br />
              </v-alert>
            </v-card-subtitle>
            <v-card-actions v-if="timestop.status === 'onHold'" class="justify-content-center text-center pt-0">
              <!-- 
                            -->
              <v-btn color="primary" @click="initiatePayment(timestop)">Mit Karte Zahlen</v-btn>
            </v-card-actions>
            <v-card-actions v-if="timestop.status === 'timeRangeChanged'" class="justify-content-center text-center pt-0">
              <v-btn color="primary" @click="viewChanges(timestop)"> Anpassung einsehen </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog dark overlay-opacity="0.8" style="overflow: hidden" @click:outside="paymentInitiated = false" v-model="paymentInitiated" persistent>
      <payment-dialog
        @completed="adyenPaymentCompleted"
        :amount="paymentAmount"
        :resortID="paymentResortID"
        :studio="paymentStudio"
        :timestopID="timestopID"
        :profileID="profileID"
        v-if="paymentInitiated"></payment-dialog>
    </v-dialog>
    <user-timestop-form
      @onClose="closeNewDialog"
      @onSuccess="closeNewDialog"
      :newEntry="true"
      v-if="showNewDialog"
      :visible="showNewDialog"
      :tacEmail="user.email"
      :showPrices="false"></user-timestop-form>
    <v-dialog overlay-opacity="0.8" @click:outside="acceptChangesTimestop = null" :value="!!acceptChangesTimestop">
      <v-card dark v-if="acceptChangesTimestop">
        <v-card-title>Änderungen deines Timestops</v-card-title>
        <v-card-text>
          Ursprünglicher Zeitraum:
          {{
            $moment(acceptChangesTimestop.timestopInfos.start).format("DD.MM.YYYY") +
            " - " +
            $moment(acceptChangesTimestop.timestopInfos.end).format("DD.MM.YYYY")
          }}
          <br />
          Neuer Zeitraum:
          <span class="font-weight-bold">
            {{
              $moment(acceptChangesTimestop.tacData.TimeSpan.Start).format("DD.MM.YYYY") +
              " - " +
              $moment(acceptChangesTimestop.tacData.TimeSpan.End).format("DD.MM.YYYY")
            }}
          </span>
        </v-card-text>

        <v-card-subtitle>
          Mit deiner Unterschrift bestätigst du die Änderungen am Timestop-Zeitraum und stimmst dem neuen Zeitraum verbindlich zu. Bitte prüfe die
          Angaben sorgfältig, bevor du bestätigst.
        </v-card-subtitle>
        <signature-pad
          class="mx-auto"
          autoSaveSignature
          :signature-data="acceptChangesTimestop.customerInfos.signature"
          @startDrawing="signature = true"
          @saveSignature="saveSignature"
          ref="signatureConditions"></signature-pad>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" @click="updateTimestop()">Bestätigen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserTimestopForm from "./UserTimestopForm.vue"
import PaymentDialog from "../../payment/paymentDialog.vue"
import signaturePad from "@/components/signaturePad/signaturePad.vue"
import userService from "../../../services/userService"

import api from "@/api"
export default {
  components: {
    signaturePad,
    UserTimestopForm,
    PaymentDialog,
  },
  props: {
    user: {
      type: Object,
    },
    userTimestops: {
      type: Array,
    },
    studios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userService,
      timestopID: "",
      profileID: "",
      paymentAmount: 0,
      paymentInitiated: false,
      paymentStudio: "",
      paymentResortID: "",
      showPrices: false,
      timestopStateMap: {
        open: "Offen",
        rejected: "Abgelehnt",
        onHold: "Timestop-Gebühr bezahlen",
        toLate: "Zahlungsfrist abgelaufen / Nicht bezahlt",
        withAttest: "mit Attest",
        withoutAttest: "ohne Attest",
        officeChangedFee: "Sondergebühr",
        payed: "Bezahlt / Nicht eingetragen",
        accepted: "Abgeschlossen",
        timeRangeChanged: "Zeitraum angepasst",
      },
      timestopStateColorMap: {
        open: "blue",
        accepted: "primary",
        rejected: "red",
        onHold: "orange",
        toLate: "red",
        payed: "blue",
        timeRangeChanged: "orange",
      },
      signature: false,
      acceptChangesTimestop: null,
      loading: false,
      firstLoad: true,
      loadingError: false,
      timestopText: "",
      showNewDialog: false,
      createPayment: false,
      acceptChangesDialog: {},
    }
  },
  methods: {
    viewChanges(timestop) {
      let timestopData = JSON.parse(JSON.stringify(timestop))
      delete timestopData.customerInfos.signature
      this.acceptChangesTimestop = timestopData
    },
    closeTimestopView() {
      this.$emit("close-view")
    },
    test(x) {
      console.log(x)
    },
    saveSignature(e) {
      this.acceptChangesTimestop.customerInfos.signature = e
    },
    async updateTimestop() {
      if (!this.acceptChangesTimestop.customerInfos.signature) return this.$toast.error("Unterschrift hinzufügen")
      this.acceptChangesTimestop.status = "onHold"
      this.acceptChangesTimestop.updatedTimeRange = true
      let upated = await api.updateTimeStopFormEntry(this.acceptChangesTimestop)
      this.acceptChangesTimestop = null
      this.$emit("reFetchTimestops")
    },
    showTimestop() {
      this.showNewDialog = true
    },

    async downloadPDF(id) {
      try {
        await api.fetchTimestopPDF(id)
      } catch (e) {}
    },
    createNewTimestop() {
      /*    if (this.userTimestops.find((timestop) => timestop.status !== 'accepted')) {
                return this.$toast.error(
                    'Du hast bereits einen offenen Antrag. Bitte warte bis dieser abgeschlossen ist.'
                )
            }*/
      this.showNewDialog = true
    },
    closeNewDialog() {
      this.showNewDialog = false
      this.$emit("reFetchTimestops")
    },
    initiatePayment(timestop) {
      this.timestopID = timestop._id
      this.profileID = timestop.profileID
      this.paymentAmount = timestop.tacData.IdlePeriodFee.value
      this.paymentInitiated = true
      this.paymentStudio = timestop.studio
      this.paymentResortID = timestop.tacData.ResortID
    },
    async adyenPaymentCompleted(response) {
      this.paymentInitiated = false
      this.paymentAmount = null
      this.$emit("reFetchTimestops")
    },
  },
  watch: {
    studios() {},
  },
}
</script>
<style lang="scss" scoped>
.mobileDeviceSize {
  height: 932px !important;
  min-width: 300px !important;
}
</style>
