<template>
  <div>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1">
      <user-mescan-tooltip type="bio_age"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Bio Age</v-card-title>
      <v-card-subtitle>in Jahren</v-card-subtitle>

      <!-- Uhr-Anzeige -->
      <div class="clock-container">
        <svg viewBox="0 0 200 200" class="clock-svg">
          <!-- Zifferblatt -->
          <circle cx="100" cy="100" r="90" stroke="grey" stroke-width="5" fill="none" />
          <!-- Stundenindikatoren -->
          <g v-for="i in 12" :key="i" :transform="'rotate(' + (i - 1) * 30 + ' 100 100)'">
            <!-- Jeder Indikator beginnt am Rand (y1=10, entspricht dem oberen Punkt) und endet 10 Einheiten weiter innen -->
            <line x1="100" y1="10" x2="100" y2="20" stroke="grey" stroke-width="3" />
          </g>
        </svg>
        <div class="clock-text" :style="{ color: ['#ff4d4d', '#ffd900', '#3c8c40'][response.rating.bioAge.index] }">
          <div class="clock-number">
            <number :from="0" :to="Math.round(response.data.bioAge.absolute.bioAge * 100) / 100"></number>
          </div>
          <div class="clock-unit">Jahre</div>
        </div>
      </div>

      <user-mescan-data-index-scala
        style="width: 100%"
        :colors="['#ff4d4d', '#ffd900', '#3c8c40']"
        years
        :scaleData="response.rating.bioAge"></user-mescan-data-index-scala>
    </v-card>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1 mt-2">
      <user-mescan-tooltip type="zellfitness"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Zellfitnesswert</v-card-title>
      <v-card-subtitle>in °</v-card-subtitle>
      <user-mescan-metabolism-cell :rating="response.rating.cellFitnessValue"></user-mescan-metabolism-cell>
      <v-card-text class="ma-0 pb-0">
        <div class="mass-breakdown">
          <div class="group">
            <li>
              <span class="color-square" :style="{ backgroundColor: colors[response.rating.cellFitnessValue.index] }"></span>
              <div class="d-flex justify-space-between" style="width: 100%">
                <span> Phasenwinkel: </span>
                <span> {{ response.data.cellFitness.absolute.cellFitnessValue }} ° </span>
              </div>
            </li>
          </div>
        </div>
      </v-card-text>
      <user-mescan-data-index-scala
        :biggerTheBetter="true"
        style="width: 100%"
        :degree="true"
        :colors="colors"
        :scaleData="response.rating.cellFitnessValue"></user-mescan-data-index-scala>
    </v-card>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl elevation-1 mt-2">
      <user-mescan-tooltip type="sauerstoffsaettigung"></user-mescan-tooltip>
      <v-card-title class="font-weight-bold">Sauerstoffsättigung</v-card-title>
      <user-mescan-metabolism-lung :percentage="response.data.oxygen.percentage.spo2"></user-mescan-metabolism-lung>
      <span class="h3 align-self-center"> <number :from="0" :to="response.data.oxygen.percentage.spo2"></number>% </span>
      <user-mescan-data-index-scala
        style="width: 100%"
        :percentage="true"
        :biggerTheBetter="true"
        :colors="['#ff4d4d', '#ffd900', '#3c8c40']"
        :scaleData="response.rating.spo2"></user-mescan-data-index-scala>
    </v-card>
    <v-card outlined class="d-flex flex-column pa-1 rounded-xl mt-2 elevation-1">
      <user-mescan-tooltip type="wasserhaushalt"></user-mescan-tooltip>
      <v-btn icon @click="waterShowPercentage = !waterShowPercentage" absolute style="top: 55px; right: 15px" large
        ><v-icon>{{ waterShowPercentage ? "mdi-weight-kilogram" : "mdi-percent-circle" }}</v-icon></v-btn
      >
      <v-card-title class="font-weight-bold">Wasserhaushalt</v-card-title>
      <v-card-subtitle>{{ waterShowPercentage ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="donut" :options="waterDonut.options" :series="waterDonut.series" height="200"></apexchart>
      <v-card-text class="ma-0 pb-0">
        <div class="mass-breakdown">
          <div class="group">
            <li>
              <span class="color-square" style="background-color: #2196f3"></span>
              <div class="d-flex justify-space-between" style="width: 100%">
                <span> Intrazelluläres Wasser: </span>
                <span>
                  {{ response.data.water[waterShowPercentage ? "percentage" : "absolute"].intraCellular }} {{ waterShowPercentage ? "%" : "kg" }}
                </span>
              </div>
            </li>
            <li>
              <span class="color-square" style="background-color: #3606f5"></span>
              <div class="d-flex justify-space-between" style="width: 100%">
                <span> Extrazelluläres Wasser: </span>
                <span>
                  {{ response.data.water[waterShowPercentage ? "percentage" : "absolute"].extraCellular }} {{ waterShowPercentage ? "%" : "kg" }}
                </span>
              </div>
            </li>
          </div>
        </div>
      </v-card-text>
      <user-mescan-data-index-scala
        style="width: 100%"
        biggerTheBetter
        :colors="colors"
        :scaleData="response.rating.waterTotal"></user-mescan-data-index-scala>
    </v-card>
  </div>
</template>

<script>
import api from "@/api"
import UserMescanDataIndexScala from "@/views/user/components/UserMescanDataIndexScala.vue"
import UserMescanMetabolismLung from "@/views/user/components/UserMescanMetabolismLung.vue"
import UserMescanMetabolismCell from "@/views/user/components/UserMescanMetabolismCell.vue"
import UserMescanTooltip from "@/views/user/components/UserMescanTooltip.vue"

export default {
  components: {
    UserMescanDataIndexScala,
    UserMescanMetabolismLung,
    UserMescanMetabolismCell,
    UserMescanTooltip,
  },
  data() {
    return {
      waterShowPercentage: false,
      colors: ["#ff4d4d", "#ff6666", "#ff9966", "#ffd900", "#66cc66", "#72ab75", "#3c8c40"],
    }
  },
  props: {
    user: {
      type: Object,
    },
    response: {
      type: Object,
    },
  },
  computed: {
    waterDonut() {
      if (!this.response.data) {
        return { options: {}, series: [] }
      }

      const water = {
        extraCellular: this.response.data.water[this.waterShowPercentage ? "percentage" : "absolute"].extraCellular,
        intraCellular: this.response.data.water[this.waterShowPercentage ? "percentage" : "absolute"].intraCellular,
      }

      // Konfiguration des ApexCharts
      const options = {
        chart: {
          type: "donut",
        },
        // Farben für die einzelnen Segmente – die ersten drei gehören zur fettfreien Masse,
        // die nächsten drei zur Fettmasse
        colors: [
          "#2196F3", // Essentielles Fett
          "#3606f5", // Fettreserve
          // Optional: "#4CAF50" als zusätzlicher Grünton, falls benötigt
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                name: {
                  fontSize: "12px",
                },
                show: true,
                value: {
                  color: "white",
                  fontSize: "20px",
                  formatter: (val) => `${val}` + (this.waterShowPercentage ? "%" : "kg"),
                },
                total: {
                  show: true,
                  color: "white",

                  label: "Gesamt",
                  formatter: (w) =>
                    this.response.data.water[this.waterShowPercentage ? "percentage" : "absolute"].total + (this.waterShowPercentage ? "%" : "kg"),
                },
              },
            },
          },
        },
        labels: ["Extrazellular", "Intrazellular"],
      }

      const series = [water.extraCellular, water.intraCellular]

      return {
        options,
        series,
        water,
      }
    },
  },
}
</script>

<style scoped>
.mass-breakdown .group {
  margin-bottom: 1rem;
}
.mass-breakdown ul {
  list-style: none;
  padding: 0;
}
.mass-breakdown li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.color-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.interactive-human-body__svg {
  width: 33%;
  height: auto;
  margin: auto;
}

/* Styling für die Uhr-Anzeige */
.clock-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 1rem auto;
}
.clock-svg {
  width: 100%;
  height: 100%;
}
.clock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: sans-serif;
}
.clock-number {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}
.clock-unit {
  font-size: 16px;
  line-height: 1;
}
</style>
