<template>
  <div class="corner">
    <v-btn @click="dialog = true" large icon>
      <v-icon class="info-icon">mdi-information</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text"> {{ title }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-5">{{ description }}</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      infoTexts: {
        gesamtuebersicht: {
          title: "Gesamtübersicht",
          description:
            "Die Gesamtübersicht deiner Körperzusammensetzung gibt dir einen umfassenden Einblick in das Verhältnis von fettfreier Masse und Fettmasse. Durch eine bioelektrische Impedanzanalyse (BIA) können verschiedene Komponenten wie Muskelmasse, Körperwasser und Fettanteil analysiert werden. Diese Werte sind entscheidend für deine sportliche Leistung, Gesundheit und dein Wohlbefinden. Ein ausgeglichener Körperkompositionswert kann durch gezielte Ernährung, Training und Regeneration erreicht werden.",
        },
        herzfrequenz: {
          title: "Herzfrequenz",
          description:
            "Die Herzfrequenz beschreibt, wie oft dein Herz pro Minute schlägt. Sie kann durch viele Faktoren wie Alter, körperliche Fitness, Stresslevel und allgemeine Lebensweise beeinflusst werden. Ein gesunder Lebensstil, regelmässige Bewegung und eine ausgewogene Ernährung helfen, die Herzfrequenz in einem optimalen Bereich zu halten.",
        },
        wasserhaushalt: {
          title: "Wasserhaushalt",
          description:
            "Der Wasserhaushalt beschreibt die Verteilung von Flüssigkeit im Körper und ist entscheidend für zahlreiche physiologische Prozesse. Der Grossteil des Körperwassers sollte innerhalb der Zellen gespeichert sein, da es dort die Zellfunktion unterstützt und den Stoffwechsel antreibt. Das extrazelluläre Wasser, das sich im Blut, Lymphsystem und Zellzwischenräumen befindet, ist ebenfalls wichtig, kann jedoch bei Ungleichgewichten zu Wassereinlagerungen führen. Faktoren wie Bewegung, Ernährung und Muskelmasse beeinflussen den Wasserhaushalt. Eine optimale Flüssigkeitsaufnahme, kombiniert mit regelmässiger Bewegung und einer ausgewogenen Ernährung, sorgt für eine gesunde Zellhydratation und unterstützt die körperliche Leistungsfähigkeit. Regelmässige körperliche Aktivität hilft zudem, überschüssiges extrazelluläres Wasser abzubauen und Wassereinlagerungen zu vermeiden.",
        },
        wasser: {
          title: "Wasser",
          description:
            "Die Flüssigkeitsversorgung bezieht sich auf den gesamten Wasseranteil im Körper und dessen Einfluss auf Gesundheit und Leistungsfähigkeit. Wasser ist an nahezu allen Stoffwechselprozessen beteiligt – es unterstützt die Zellfunktion, verbessert die Blutzirkulation und hilft, Giftstoffe auszuscheiden. Der tägliche Flüssigkeitsbedarf hängt von Aktivitätsniveau, Umgebungstemperatur und Ernährung ab. Besonders bei sportlicher Betätigung oder hohen Temperaturen ist eine kontinuierliche Wasserzufuhr wichtig. Ein Wassermangel kann zu Müdigkeit, Konzentrationsproblemen und einem verlangsamten Stoffwechsel führen. Daher ist es entscheidend, regelmässig zu trinken, bevor Durst auftritt.",
        },
        hrv: {
          title: "HRV-Trainingsbereitschaft",
          description:
            "Die Herzratenvariabilität (HRV) gibt Hinweise darauf, wie gut sich dein Körper von Stress oder Training erholt hat. Ein hoher HRV-Wert deutet in der Regel auf eine gute Erholung und eine ausgeglichene Balance des autonomen Nervensystems hin. Ein niedriger Wert kann hingegen auf Übertraining oder hohe Stressbelastung hindeuten. Durch angepasstes Training und gezielte Regenerationsmassnahmen kannst du deine HRV verbessern und dein Training optimieren.",
        },
        fettmasse: {
          title: "Fettmasse",
          description:
            "Die Fettmasse setzt sich aus essenziellen Fetten, Fettreserven und überschüssigem Fett zusammen. Essenzielle Fette sind für den Zellstoffwechsel notwendig, während Fettreserven als Energiereserve dienen. Ein Überschuss an Fett, besonders viszerales Fett, kann jedoch das Risiko für Herz-Kreislauf-Erkrankungen erhöhen. Eine gesunde Ernährung und regelmässiges Training helfen, das Körperfett in einem optimalen Bereich zu halten.",
        },
        viszerales_fett: {
          title: "Viszerales Fett",
          description:
            "Viszerales Fett lagert sich um innere Organe wie Leber, Bauchspeicheldrüse und Darm an. Im Gegensatz zum subkutanen Fett, das direkt unter der Haut sitzt, hat viszerales Fett einen stärkeren Einfluss auf den Stoffwechsel und kann das Risiko für Bluthochdruck, Diabetes und Herzkrankheiten erhöhen. Es lässt sich effektiv durch eine Kombination aus Krafttraining, Ausdauertraining und einer gesunden Ernährung reduzieren.",
        },
        muskelverteilung: {
          title: "Muskelverteilung",
          description:
            "Die Muskelverteilung gibt Aufschluss darüber, wie sich die Muskelmasse auf verschiedene Körperbereiche verteilt. Der Rumpf, die Arme und die Beine spielen eine entscheidende Rolle für die allgemeine Kraft und Stabilität. Durch gezieltes Krafttraining kannst du muskuläre Dysbalancen ausgleichen und eine symmetrische Muskelentwicklung fördern.",
        },
        bio_age: {
          title: "Biologisches Alter",
          description:
            "Das biologische Alter gibt Aufschluss über den allgemeinen Gesundheitszustand deines Körpers auf Zellebene. Es kann sich von deinem tatsächlichen Alter unterscheiden und wird durch Faktoren wie Muskelmasse, Fettverteilung, Zellgesundheit und Lebensstil beeinflusst. Eine gesunde Ernährung, regelmässige Bewegung und eine gute Regeneration tragen dazu bei, dein biologisches Alter positiv zu beeinflussen.",
        },
        sauerstoffsaettigung: {
          title: "Sauerstoffsättigung",
          description:
            "Die Sauerstoffsättigung beschreibt, wie gut dein Blut mit Sauerstoff versorgt ist. Sie ist essenziell für die Energieproduktion und den Zellstoffwechsel. Ein zu niedriger Wert kann durch Atemprobleme oder Kreislauferkrankungen verursacht werden. Regelmässige Atemübungen und körperliche Aktivität können helfen, die Sauerstoffaufnahme zu optimieren.",
        },
        zellfitness: {
          title: "Zellfitness",
          description:
            "Der Zellfitnesswert gibt an, wie gesund und widerstandsfähig deine Zellen sind. Eine optimale Zellgesundheit sorgt für eine effiziente Nährstoffaufnahme und einen aktiven Stoffwechsel. Faktoren wie Ernährung, Flüssigkeitsaufnahme und Trainingsbelastung beeinflussen diesen Wert massgeblich.",
        },
      },
    }
  },
  computed: {
    title() {
      return this.infoTexts[this.type]?.title || "Information"
    },
    description() {
      return this.infoTexts[this.type]?.description || "Keine Informationen verfügbar."
    },
  },
}
</script>

<style scoped>
.corner {
  position: absolute;
  right: 15px;
  top: 15px;
}
.info-icon {
  cursor: pointer;
  color: white;
}
</style>
