<template>
  <div class="cell-svg-container">
    <!-- Basis: ganze Zelle mit reduzierter Opacity -->
    <div class="cell-svg-base">
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle style="fill: #f56e92" cx="256" cy="256" r="256" />
        <circle style="fill: #fccfdb" cx="256" cy="256" r="222.242" />
        <g>
          <circle class="rotating-element" style="fill: #f99fb6" cx="221.679" cy="425.354" r="8.44" />
          <circle class="rotating-element" style="fill: #f99fb6" cx="202.549" cy="92.835" r="8.44" />
        </g>
        <circle style="fill: #ff9e46" cx="256" cy="111.267" r="22.505" />
        <g>
          <path
            style="fill: #ff7226"
            d="M168.716,395.899c-4.673,0-9.386-1.449-13.422-4.455
            c-13.212-9.837-24.899-21.524-34.737-34.736
            c-7.423-9.969-5.359-24.069,4.61-31.491c9.968-7.425,24.067-5.36,31.492,4.61
            c7.227,9.704,15.811,18.289,25.514,25.514
            c9.97,7.423,12.034,21.522,4.611,31.492C182.368,392.766,175.585,395.899,168.716,395.899z" />
          <path
            style="fill: #ff7226"
            d="M420.798,261.641c2.337,4.048,3.438,8.853,2.854,13.852
            c-1.914,16.36-6.191,32.325-12.715,47.451
            c-4.922,11.414-18.164,16.675-29.578,11.752
            c-11.414-4.92-16.675-18.163-11.754-29.578
            c4.791-11.111,7.933-22.837,9.339-34.853
            c1.444-12.345,12.621-21.183,24.968-19.74
            C411.259,251.384,417.363,255.693,420.798,261.641z" />
          <path
            style="fill: #ff7226"
            d="M343.284,116.101c4.673,0,9.386,1.449,13.422,4.455
            c13.212,9.837,24.899,21.524,34.737,34.736
            c7.423,9.969,5.359,24.069-4.61,31.491
            c-9.968,7.425-24.067,5.36-31.492-4.61
            c-7.227-9.704-15.811-18.289-25.514-25.514
            c-9.97-7.423-12.034-21.522-4.611-31.492
            C329.632,119.234,336.416,116.101,343.284,116.101z" />
        </g>
        <g class="nucleus-group">
          <circle class="nucleus" style="fill: #f23e6d" cx="256" cy="256" r="70.33" />
        </g>
      </svg>
    </div>
    <!-- Overlay: hier wird per clip-path der "gefüllte" Anteil von unten angezeigt -->
    <div class="cell-svg-filled" :style="{ clipPath: 'inset(' + (1 - cellFillPercentage) * 100 + '% 0 0 0)' }">
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle style="fill: #f56e92" cx="256" cy="256" r="256" />
        <circle style="fill: #fccfdb" cx="256" cy="256" r="222.242" />
        <g>
          <circle class="rotating-element" style="fill: #f99fb6" cx="221.679" cy="425.354" r="8.44" />
          <circle class="rotating-element" style="fill: #f99fb6" cx="202.549" cy="92.835" r="8.44" />
        </g>
        <circle style="fill: #ff9e46" cx="256" cy="111.267" r="22.505" />
        <g>
          <path
            style="fill: #ff7226"
            d="M168.716,395.899c-4.673,0-9.386-1.449-13.422-4.455
            c-13.212-9.837-24.899-21.524-34.737-34.736
            c-7.423-9.969-5.359-24.069,4.61-31.491c9.968-7.425,24.067-5.36,31.492,4.61
            c7.227,9.704,15.811,18.289,25.514,25.514
            c9.97,7.423,12.034,21.522,4.611,31.492C182.368,392.766,175.585,395.899,168.716,395.899z" />
          <path
            style="fill: #ff7226"
            d="M420.798,261.641c2.337,4.048,3.438,8.853,2.854,13.852
            c-1.914,16.36-6.191,32.325-12.715,47.451
            c-4.922,11.414-18.164,16.675-29.578,11.752
            c-11.414-4.92-16.675-18.163-11.754-29.578
            c4.791-11.111,7.933-22.837,9.339-34.853
            c1.444-12.345,12.621-21.183,24.968-19.74
            C411.259,251.384,417.363,255.693,420.798,261.641z" />
          <path
            style="fill: #ff7226"
            d="M343.284,116.101c4.673,0,9.386,1.449,13.422,4.455
            c13.212,9.837,24.899,21.524,34.737,34.736
            c7.423,9.969,5.359,24.069-4.61,31.491
            c-9.968,7.425-24.067,5.36-31.492-4.61
            c-7.227-9.704-15.811-18.289-25.514-25.514
            c-9.97-7.423-12.034-21.522-4.611-31.492
            C329.632,119.234,336.416,116.101,343.284,116.101z" />
        </g>
        <g class="nucleus-group">
          <circle class="nucleus" style="fill: #f23e6d" cx="256" cy="256" r="70.33" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CellSvg",
  props: {
    rating: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cellFillPercentage() {
      const total = this.rating.ranges.absolute.length
      const index = this.rating.index
      return (index + 1) / total
    },
  },
}
</script>

<style scoped>
.cell-svg-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 1rem;
  transition: transform 0.3s ease; /* Smooth scaling on hover */
}

.cell-svg-container:hover {
  transform: scale(1.1); /* Slight scale-up on hover */
}

.cell-svg-base,
.cell-svg-filled {
  width: 100%;
  height: 100%;
}

.cell-svg-base {
  opacity: 0.3;
}

.cell-svg-filled {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  animation: fillAnimation 1.5s ease-in-out forwards; /* Smooth fill animation */
}

/* Pulsing nucleus animation */
.nucleus {
  animation: pulse 2s infinite ease-in-out;
}

/* Rotating small elements */
.rotating-element {
  animation: rotate 1.5s infinite linear;
}

/* Keyframes for animations */
@keyframes fillAnimation {
  0% {
    clip-path: inset(100% 0 0 0); /* Start fully clipped */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
