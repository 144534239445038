<template>
  <div>
    <!-- Datum anzeigen -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Verglichene Messungen</v-card-title>
      <v-card-text style="display: flex; justify-content: space-around">
        <span> {{ formattedOldMeasurementDate }}</span>
        <v-icon>mdi-arrow-right</v-icon>
        <span> {{ formattedNewMeasurementDate }}</span>
      </v-card-text>
    </v-card>
    <!-- Körpergewicht Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Körpergewicht Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in weightComparison" :key="'weight-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>
    <!-- Gesamtübersicht Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Gesamtübersicht Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in overallComparison" :key="'overall-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Muskelmasse Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Muskelmasse Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in muscleComparison" :key="'muscle-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Fettmasse Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Fettmasse Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in fatComparison" :key="'fat-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Viszerales Fett Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Viszerales Fett Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in visceralComparison" :key="'visceral-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Wasser Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Wasser Vergleich</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in waterComparison" :key="'water-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} {{ item.unit }}</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} {{ item.unit }}</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MeasurementComparison",
  props: {
    measurement1: {
      type: Object,
      required: true,
    },
    measurement2: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Vergleicht zwei Werte und berechnet den prozentualen Unterschied.
    // increaseIsGood gibt an, ob eine Zunahme (true) oder Abnahme (false) eine Verbesserung darstellt.
    compareMetric(label, value1, value2, unit, increaseIsGood, noColor = false) {
      // Spezieller Fall: Bei "Viszerales Fett" immer 0% anzeigen, wenn value1 0 ist.
      if (value1 === 0 && value2 === 0) {
        return {
          label,
          value1: value1.toFixed(1),
          value2: value2.toFixed(1),
          unit,
          percentChange: "0",
          arrowIcon: "",
          diffColor: "#ffffff",
        }
      }

      const change = ((value2 - value1) / value1) * 100
      const formatted = Math.abs(change).toFixed(1)
      let arrowIcon = ""
      let diffColor = "#ffffff"
      if (change === 0) {
        arrowIcon = ""
        diffColor = "#ffffff"
      } else {
        // Bei increaseIsGood: positive Veränderung ist Verbesserung.
        // Bei !increaseIsGood: negative Veränderung ist Verbesserung.
        const isImprovement = increaseIsGood ? change > 0 : change < 0
        if (isImprovement) {
          arrowIcon = "mdi-arrow-up"
          diffColor = "green"
        } else {
          arrowIcon = "mdi-arrow-down"
          diffColor = "red"
        }
      }

      if (noColor) diffColor = "white"
      return {
        label,
        value1: value1.toFixed(1),
        value2: value2.toFixed(1),
        unit,
        percentChange: formatted,
        arrowIcon,
        diffColor,
      }
    },
  },
  computed: {
    // Alte und neue Messung anhand des Datums ermitteln
    oldMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement1 : this.measurement2
    },
    newMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement2 : this.measurement1
    },
    formattedOldMeasurementDate() {
      return new Date(this.oldMeasurement.date).toLocaleDateString()
    },
    formattedNewMeasurementDate() {
      return new Date(this.newMeasurement.date).toLocaleDateString()
    },
    // Körpergewicht Vergleich (neu hinzugefügt)
    weightComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      // Hier wird angenommen, dass eine Abnahme des Körpergewichts (Gewichtsverlust) als Verbesserung gilt.
      return [this.compareMetric("Körpergewicht", oldM.userInfo.weight, newM.userInfo.weight, "kg", true, true)]
    },
    // Gesamtübersicht:
    overallComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      // Berechne passives Gewebe als: Gewicht - (Wasser + Muskelprotein + essentielles Fett + Fettreserve + überschüssiges Fett)
      const calcPassive = (m) => {
        const weight = m.userInfo.weight
        const water = m.data.water.absolute.total
        const muscleProtein = m.data.muscle.absolute.muscleProtein
        const essentialFat = m.data.fat.absolute.essentialFat
        const fatReserve = m.data.fat.absolute.fatReserve
        const excessiveFat = m.data.fat.absolute.excessiveFat
        return weight - (water + muscleProtein + essentialFat + fatReserve + excessiveFat)
      }
      return [
        this.compareMetric("Wasser", oldM.data.water.absolute.total, newM.data.water.absolute.total, "kg", true),
        this.compareMetric("Muskelprotein", oldM.data.muscle.absolute.muscleProtein, newM.data.muscle.absolute.muscleProtein, "kg", true),
        this.compareMetric("Passives Gewebe", calcPassive(oldM), calcPassive(newM), "kg", false),
        this.compareMetric("Essentielles Fett", oldM.data.fat.absolute.essentialFat, newM.data.fat.absolute.essentialFat, "kg", false),
        this.compareMetric("Fettreserve", oldM.data.fat.absolute.fatReserve, newM.data.fat.absolute.fatReserve, "kg", false),
        this.compareMetric("Überschüssiges Fett", oldM.data.fat.absolute.excessiveFat, newM.data.fat.absolute.excessiveFat, "kg", false),
      ]
    },
    // Muskelmasse:
    muscleComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      return [
        this.compareMetric("Gesamt", oldM.data.muscle.absolute.total, newM.data.muscle.absolute.total, "kg", true),
        this.compareMetric("Torso", oldM.data.muscle.absolute.trunk, newM.data.muscle.absolute.trunk, "kg", true),
        this.compareMetric("Arm R", oldM.data.muscle.absolute.rightArm, newM.data.muscle.absolute.rightArm, "kg", true),
        this.compareMetric("Arm L", oldM.data.muscle.absolute.leftArm, newM.data.muscle.absolute.leftArm, "kg", true),
        this.compareMetric("Bein R", oldM.data.muscle.absolute.rightLeg, newM.data.muscle.absolute.rightLeg, "kg", true),
        this.compareMetric("Bein L", oldM.data.muscle.absolute.leftLeg, newM.data.muscle.absolute.leftLeg, "kg", true),
      ]
    },
    // Fettmasse:
    fatComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      return [
        this.compareMetric("Essentielles Fett", oldM.data.fat.absolute.essentialFat, newM.data.fat.absolute.essentialFat, "kg", false),
        this.compareMetric("Fettreserve", oldM.data.fat.absolute.fatReserve, newM.data.fat.absolute.fatReserve, "kg", false),
        this.compareMetric("Überschüssiges Fett", oldM.data.fat.absolute.excessiveFat, newM.data.fat.absolute.excessiveFat, "kg", false),
      ]
    },
    // Viszerales Fett:
    visceralComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      return [this.compareMetric("Viszerales Fett", oldM.data.fat.absolute.visceralFat, newM.data.fat.absolute.visceralFat, "kg", false)]
    },
    // Wasser:
    waterComparison() {
      const oldM = this.oldMeasurement
      const newM = this.newMeasurement
      return [this.compareMetric("Wasser Total", oldM.data.water.absolute.total, newM.data.water.absolute.total, "kg", true)]
    },
  },
}
</script>

<style scoped>
.comparison-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.comparison-label {
  flex: 1;
  font-weight: bold;
}
.comparison-value {
  flex: 1;
  text-align: center;
}
.comparison-diff {
  flex: 1;
  text-align: center;
  font-weight: bold;
}
.measurement-dates {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffffff;
}
</style>
