<template>
  <div>
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Körpergewicht</v-card-title>
      <v-card-subtitle>in kg</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="weightSeries"></apexchart>
    </v-card>
    <!-- Gesamtübersicht: Wasser, Muskelprotein, Passives Gewebe, Essentielles Fett, Fettreserve, Überschüssiges Fett -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="toggles.overall = !toggles.overall" absolute top right>
        <v-icon>{{ toggles.overall ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Gesamtübersicht</v-card-title>
      <v-card-subtitle>{{ toggles.overall ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="overallSeries"></apexchart>
    </v-card>

    <!-- Muskelmasse: Gesamt, Torso, Arm R, Arm L, Bein R, Bein L -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="toggles.muscle = !toggles.muscle" absolute top right>
        <v-icon>{{ toggles.muscle ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Muskelmasse</v-card-title>
      <v-card-subtitle>{{ toggles.muscle ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="muscleSeries"></apexchart>
    </v-card>

    <!-- Fettmasse: Essentielles Fett, Fettreserve, Überschüssiges Fett -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="toggles.fat = !toggles.fat" absolute top right>
        <v-icon>{{ toggles.fat ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Fettmasse</v-card-title>
      <v-card-subtitle>{{ toggles.fat ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="fatSeries"></apexchart>
    </v-card>

    <!-- Viszerales Fett -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="toggles.visceral = !toggles.visceral" absolute top right>
        <v-icon>{{ toggles.visceral ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Viszerales Fett</v-card-title>
      <v-card-subtitle>{{ toggles.visceral ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="visceralSeries"></apexchart>
    </v-card>

    <!-- Wasser: Total, ExtraCellular, IntraCellular -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-btn icon @click="toggles.water = !toggles.water" absolute top right>
        <v-icon>{{ toggles.water ? "mdi-percent-circle" : "mdi-weight-kilogram" }}</v-icon>
      </v-btn>
      <v-card-title>Wasser</v-card-title>
      <v-card-subtitle>{{ toggles.water ? "in %" : "in kg" }}</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="waterSeries"></apexchart>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UserMescanStatistics",
  props: {
    measurements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Toggle-Buttons: true = Prozentanzeige, false = absolute Werte (kg)
      toggles: {
        overall: false,
        muscle: false,
        fat: false,
        visceral: false,
        water: false,
      },
      // Basisoptionen für alle Charts (zentral verwaltbar)
      baseChartOptions: {
        chart: {
          type: "line",
          zoom: { enabled: false },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: true,
          position: "top",
          labels: {
            colors: "#ffffff",
          },
        },
        tooltip: {
          theme: "dark",
        },
      },
      // Farbdefinitionen exakt wie in deinem anderen File
      colors: {
        overall: {
          water: "#2196F3",
          muscleProtein: "#8D6E63",
          passiveTissue: "#FCCABF",
          essentialFat: "#FFA726",
          fatReserve: "#FFCC80",
          excessiveFat: "#FFE082",
        },
        muscle: {
          overall: "#ff4d4d",
          trunk: "#ff6666",
          rightArm: "#ff9966",
          leftArm: "#ffd900",
          rightLeg: "#66cc66",
          leftLeg: "#72ab75",
        },
        fat: {
          essentialFat: "#FFA726",
          fatReserve: "#FFCC80",
          excessiveFat: "#FFE082",
        },
        visceral: {
          visceralFat: "#3c8c40",
        },
        water: {
          total: "#2196F3",
          extraCellular: "#8D6E63",
          intraCellular: "#FCCABF",
        },
        weight: "#FF5722", // Neue Farbe fuer das Körpergewicht
      },
    }
  },
  computed: {
    sortedMeasurements() {
      return this.measurements.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    // X-Achsen-Beschriftung: Datum der Messungen
    categories() {
      return this.sortedMeasurements.map((m) => new Date(m.date).toLocaleDateString())
    },
    // Gesamtübersicht: Wasser, Muskelprotein, Passives Gewebe, Essentielles Fett, Fettreserve, Überschüssiges Fett
    weightSeries() {
      return [
        {
          name: "Körpergewicht",
          color: this.colors.weight,
          data: this.sortedMeasurements.map((m) => m.userInfo.weight),
        },
      ]
    },
    overallSeries() {
      return [
        {
          name: "Wasser",
          color: this.colors.overall.water,
          data: this.sortedMeasurements.map((m) => (this.toggles.overall ? m.data.water.percentage.total : m.data.water.absolute.total)),
        },
        {
          name: "Muskelprotein",
          color: this.colors.overall.muscleProtein,
          data: this.sortedMeasurements.map((m) =>
            this.toggles.overall
              ? Number(((m.data.muscle.absolute.muscleProtein / m.userInfo.weight) * 100).toFixed(1))
              : m.data.muscle.absolute.muscleProtein
          ),
        },
        {
          name: "Passives Gewebe",
          color: this.colors.overall.passiveTissue,
          data: this.sortedMeasurements.map((m) => {
            const weight = m.userInfo.weight
            const water = m.data.water.absolute.total
            const muscleProtein = m.data.muscle.absolute.muscleProtein
            const essentialFat = m.data.fat.absolute.essentialFat
            const fatReserve = m.data.fat.absolute.fatReserve
            const excessiveFat = m.data.fat.absolute.excessiveFat
            const passive = weight - (water + muscleProtein + essentialFat + fatReserve + excessiveFat)
            return this.toggles.overall ? Number(((passive / weight) * 100).toFixed(1)) : Number(passive.toFixed(1))
          }),
        },
        {
          name: "Essentielles Fett",
          color: this.colors.overall.essentialFat,
          data: this.sortedMeasurements.map((m) => (this.toggles.overall ? m.data.fat.percentage.essentialFat : m.data.fat.absolute.essentialFat)),
        },
        {
          name: "Fettreserve",
          color: this.colors.overall.fatReserve,
          data: this.sortedMeasurements.map((m) => (this.toggles.overall ? m.data.fat.percentage.fatReserve : m.data.fat.absolute.fatReserve)),
        },
        {
          name: "Überschüssiges Fett",
          color: this.colors.overall.excessiveFat,
          data: this.sortedMeasurements.map((m) => (this.toggles.overall ? m.data.fat.percentage.excessiveFat : m.data.fat.absolute.excessiveFat)),
        },
      ]
    },
    // Muskelmasse: Gesamt, Torso, Arm R, Arm L, Bein R, Bein L
    muscleSeries() {
      return [
        {
          name: "Gesamt",
          color: this.colors.muscle.overall,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.total : m.data.muscle.absolute.total)),
        },
        {
          name: "Torso",
          color: this.colors.muscle.trunk,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.trunk : m.data.muscle.absolute.trunk)),
        },
        {
          name: "Arm R",
          color: this.colors.muscle.rightArm,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.rightArm : m.data.muscle.absolute.rightArm)),
        },
        {
          name: "Arm L",
          color: this.colors.muscle.leftArm,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.leftArm : m.data.muscle.absolute.leftArm)),
        },
        {
          name: "Bein R",
          color: this.colors.muscle.rightLeg,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.rightLeg : m.data.muscle.absolute.rightLeg)),
        },
        {
          name: "Bein L",
          color: this.colors.muscle.leftLeg,
          data: this.sortedMeasurements.map((m) => (this.toggles.muscle ? m.data.muscle.percentage.leftLeg : m.data.muscle.absolute.leftLeg)),
        },
      ]
    },
    // Fettmasse: Essentielles Fett, Fettreserve, Überschüssiges Fett
    fatSeries() {
      return [
        {
          name: "Essentielles Fett",
          color: this.colors.fat.essentialFat,
          data: this.sortedMeasurements.map((m) => (this.toggles.fat ? m.data.fat.percentage.essentialFat : m.data.fat.absolute.essentialFat)),
        },
        {
          name: "Fettreserve",
          color: this.colors.fat.fatReserve,
          data: this.sortedMeasurements.map((m) => (this.toggles.fat ? m.data.fat.percentage.fatReserve : m.data.fat.absolute.fatReserve)),
        },
        {
          name: "Überschüssiges Fett",
          color: this.colors.fat.excessiveFat,
          data: this.sortedMeasurements.map((m) => (this.toggles.fat ? m.data.fat.percentage.excessiveFat : m.data.fat.absolute.excessiveFat)),
        },
      ]
    },
    // Viszerales Fett
    visceralSeries() {
      return [
        {
          name: "Viszerales Fett",
          color: this.colors.visceral.visceralFat,
          data: this.sortedMeasurements.map((m) => (this.toggles.visceral ? m.data.fat.percentage.visceralFat : m.data.fat.absolute.visceralFat)),
        },
      ]
    },
    // Wasser: Total, ExtraCellular, IntraCellular
    waterSeries() {
      return [
        {
          name: "Wasser Total",
          color: this.colors.water.total,
          data: this.sortedMeasurements.map((m) => (this.toggles.water ? m.data.water.percentage.total : m.data.water.absolute.total)),
        },
      ]
    },
  },
  methods: {
    // Zentrale Options-Konfiguration inklusive X- und Y-Achsen (Datum, Labels in Weiß)
    getChartOptions() {
      return {
        ...this.baseChartOptions,
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              colors: "#ffffff",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#ffffff",
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
.mb-4 {
  margin-bottom: 1rem;
}
</style>
