<template>
  <div>
    <!-- Herzfrequenz -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Herzfrequenz</v-card-title>
      <v-card-subtitle>in Schlägen pro Minute (bpm)</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="heartRateSeries"></apexchart>
    </v-card>

    <!-- HRV-Trainingsbereitschaft -->
    <v-card outlined class="mb-4">
      <v-card-title class="font-weight-bold">HRV-Trainingsbereitschaft</v-card-title>
      <v-card-subtitle>in ms</v-card-subtitle>
      <apexchart type="line" height="300" :options="getChartOptions()" :series="workoutReadinessSeries"></apexchart>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MultiMeasurementHeartStatistics",
  props: {
    measurements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Zentrale Basisoptionen – alle Labels in Weiß, Tooltip dunkel
      baseChartOptions: {
        chart: {
          type: "line",
          zoom: { enabled: false },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
          style: { colors: ["#ffffff"] },
        },
        legend: {
          show: true,
          position: "top",
          labels: { colors: "#ffffff" },
        },
        tooltip: {
          theme: "dark",
        },
      },
    }
  },
  computed: {
    sortedMeasurements() {
      return this.measurements.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    // X-Achsen-Beschriftungen: Datum der Messungen
    categories() {
      return this.sortedMeasurements.map((m) => new Date(m.date).toLocaleDateString())
    },
    // Serie für Herzfrequenz: Wert aus m.data.heartRate.absolute.avgHeartRate
    heartRateSeries() {
      return [
        {
          name: "Herzfrequenz",
          data: this.sortedMeasurements.map((m) => m.data.heartRate.absolute.avgHeartRate),
          color: "#ff4d4d", // erste Farbe deines Arrays
        },
      ]
    },
    // Serie für HRV-Trainingsbereitschaft: Wert aus m.data.workoutReadiness.absolute.workoutReadiness
    workoutReadinessSeries() {
      return [
        {
          name: "HRV-Trainingsbereitschaft",
          data: this.sortedMeasurements.map((m) => m.data.workoutReadiness.absolute.workoutReadiness),
          color: "#ff6666", // zweite Farbe deines Arrays
        },
      ]
    },
  },
  methods: {
    // Zentrale Options-Konfiguration, ergänzt um X- und Y-Achsen (mit weißen Labels)
    getChartOptions(extra = {}) {
      return {
        ...this.baseChartOptions,
        ...extra,
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              colors: "#ffffff",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#ffffff",
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
.mb-4 {
  margin-bottom: 1rem;
}
</style>
