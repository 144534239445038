<template>
  <div>
    <!-- Anzeige der Messungsdaten -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title>Verglichene Messungen</v-card-title>
      <v-card-text style="display: flex; justify-content: space-around">
        <span> {{ formattedOldMeasurementDate }}</span>
        <v-icon>mdi-arrow-right</v-icon>
        <span> {{ formattedNewMeasurementDate }}</span>
      </v-card-text>
    </v-card>

    <!-- Bio Age Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Bio Age Vergleich</v-card-title>
      <v-card-subtitle>in Jahren</v-card-subtitle>
      <v-card-text>
        <div class="comparison-row">
          <span class="comparison-label">Bio Age</span>
          <span class="comparison-value">{{ bioAgeComparison.value1 }} Jahre</span>
          <span class="comparison-diff" :style="{ color: bioAgeComparison.diffColor }">
            <v-icon v-if="bioAgeComparison.arrowIcon" small>{{ bioAgeComparison.arrowIcon }}</v-icon>
            {{ bioAgeComparison.percentChange }}%
          </span>
          <span class="comparison-value">{{ bioAgeComparison.value2 }} Jahre</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Zellfitnesswert Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Zellfitnesswert Vergleich</v-card-title>
      <v-card-subtitle>in °</v-card-subtitle>
      <v-card-text>
        <div class="comparison-row">
          <span class="comparison-label">Zellfitnesswert</span>
          <span class="comparison-value">{{ cellFitnessComparison.value1 }} °</span>
          <span class="comparison-diff" :style="{ color: cellFitnessComparison.diffColor }">
            <v-icon v-if="cellFitnessComparison.arrowIcon" small>{{ cellFitnessComparison.arrowIcon }}</v-icon>
            {{ cellFitnessComparison.percentChange }}%
          </span>
          <span class="comparison-value">{{ cellFitnessComparison.value2 }} °</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Sauerstoffsättigung Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Sauerstoffsättigung Vergleich</v-card-title>
      <v-card-subtitle>in %</v-card-subtitle>
      <v-card-text>
        <div class="comparison-row">
          <span class="comparison-label">Sauerstoffsättigung</span>
          <span class="comparison-value">{{ spo2Comparison.value1 }} %</span>
          <span class="comparison-diff" :style="{ color: spo2Comparison.diffColor }">
            <v-icon v-if="spo2Comparison.arrowIcon" small>{{ spo2Comparison.arrowIcon }}</v-icon>
            {{ spo2Comparison.percentChange }}%
          </span>
          <span class="comparison-value">{{ spo2Comparison.value2 }} %</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Wasser Vergleich -->
    <v-card outlined class="mb-4 rounded-xl">
      <v-card-title class="font-weight-bold">Wasser Vergleich</v-card-title>
      <v-card-subtitle>in kg</v-card-subtitle>
      <v-card-text>
        <div v-for="(item, index) in waterComparison" :key="'water-' + index" class="comparison-row">
          <span class="comparison-label">{{ item.label }}</span>
          <span class="comparison-value">{{ item.value1 }} kg</span>
          <span class="comparison-diff" :style="{ color: item.diffColor }">
            <v-icon v-if="item.arrowIcon" small>{{ item.arrowIcon }}</v-icon>
            {{ item.percentChange }}%
          </span>
          <span class="comparison-value">{{ item.value2 }} kg</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MeasurementComparisonAdvanced",
  props: {
    measurement1: {
      type: Object,
      required: true,
    },
    measurement2: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Vergleicht zwei Werte und berechnet den prozentualen Unterschied.
    // increaseIsGood gibt an, ob eine Zunahme (true) oder Abnahme (false) eine Verbesserung darstellt.
    compareMetric(label, value1, value2, unit, increaseIsGood) {
      // Falls value1 0 ist, geben wir "N/A" zurück (außer bei Wasser, wo 0% gewünscht ist)
      if (value1 === 0) {
        // Für Wasser (oder spezifisch "Extrazelluläres" / "Intrazelluläres Wasser") setzen wir 0%
        if (label.indexOf("Wasser") !== -1) {
          return {
            label,
            value1: value1.toFixed(1),
            value2: value2.toFixed(1),
            unit,
            percentChange: "0",
            arrowIcon: "",
            diffColor: "#ffffff",
          }
        }
        return {
          label,
          value1: value1.toFixed(1),
          value2: value2.toFixed(1),
          unit,
          percentChange: "N/A",
          arrowIcon: "",
          diffColor: "#ffffff",
        }
      }
      const change = ((value2 - value1) / value1) * 100
      const formatted = Math.abs(change).toFixed(1)
      let arrowIcon = ""
      let diffColor = "#ffffff"
      if (change === 0) {
        arrowIcon = ""
        diffColor = "#ffffff"
      } else {
        // Bei increaseIsGood: positive Veränderung ist Verbesserung.
        // Bei !increaseIsGood: negative Veränderung ist Verbesserung.
        const isImprovement = increaseIsGood ? change > 0 : change < 0
        if (isImprovement) {
          arrowIcon = "mdi-arrow-up"
          diffColor = "green"
        } else {
          arrowIcon = "mdi-arrow-down"
          diffColor = "red"
        }
      }
      return {
        label,
        value1: value1.toFixed(1),
        value2: value2.toFixed(1),
        unit,
        percentChange: formatted,
        arrowIcon,
        diffColor,
      }
    },
  },
  computed: {
    // Bestimme, welche Messung älter (old) und welche neuer (new) ist
    oldMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement1 : this.measurement2
    },
    newMeasurement() {
      return new Date(this.measurement1.date) <= new Date(this.measurement2.date) ? this.measurement2 : this.measurement1
    },
    formattedOldMeasurementDate() {
      return new Date(this.oldMeasurement.date).toLocaleDateString()
    },
    formattedNewMeasurementDate() {
      return new Date(this.newMeasurement.date).toLocaleDateString()
    },
    // Bio Age Vergleich (in Jahren): Hier ist eine niedrigere Bio Age besser → increaseIsGood = false.
    bioAgeComparison() {
      return this.compareMetric(
        "Bio Age",
        this.oldMeasurement.data.bioAge.absolute.bioAge,
        this.newMeasurement.data.bioAge.absolute.bioAge,
        "Jahre",
        false
      )
    },
    // Zellfitnesswert Vergleich (in °): Höhere Werte sind hier besser → increaseIsGood = true.
    cellFitnessComparison() {
      return this.compareMetric(
        "Zellfitnesswert",
        this.oldMeasurement.data.cellFitness.absolute.cellFitnessValue,
        this.newMeasurement.data.cellFitness.absolute.cellFitnessValue,
        "°",
        true
      )
    },
    // Sauerstoffsättigung Vergleich (in %): Höhere Werte sind besser → increaseIsGood = true.
    spo2Comparison() {
      return this.compareMetric(
        "Sauerstoffsättigung",
        this.oldMeasurement.data.oxygen.percentage.spo2,
        this.newMeasurement.data.oxygen.percentage.spo2,
        "%",
        true
      )
    },
    // Wasser Vergleich: Für beide Reihen (Extrazelluläres und Intrazelluläres Wasser) ist ein höherer Wert besser.
    waterComparison() {
      return [
        this.compareMetric(
          "Extrazelluläres Wasser",
          this.oldMeasurement.data.water.absolute.extraCellular,
          this.newMeasurement.data.water.absolute.extraCellular,
          "kg",
          true
        ),
        this.compareMetric(
          "Intrazelluläres Wasser",
          this.oldMeasurement.data.water.absolute.intraCellular,
          this.newMeasurement.data.water.absolute.intraCellular,
          "kg",
          true
        ),
      ]
    },
  },
}
</script>

<style scoped>
.measurement-dates {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.comparison-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.comparison-label {
  flex: 1;
  font-weight: bold;
}
.comparison-value {
  flex: 1;
  text-align: center;
}
.comparison-diff {
  flex: 1;
  text-align: center;
  font-weight: bold;
}
</style>
