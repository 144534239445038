<template>
  <v-card dark style="border-radius: 0px !important">
    <v-row v-if="errorFetchingMeScans" no-gutters>
      <v-col cols="12">
        <v-card class="secondary darken-2 pa-3 mt-5 my-3 rounded-xl" elevation="1">
          <v-badge
            content="NEU"
            overlap
            style="position: absolute; top: 0%; left: 50%; transform: translate(-50%, -50%); font-weight: bold"
            color="primary">
          </v-badge>

          <v-card-text class="white--text"> Hier kannst du deine MeScan Ergebnisse einsehen und deinen Fortschritt tracken. </v-card-text>

          <v-card-text class="white--text"> Falls du noch kein MeScan Zusatzpaket gelöst hast, kannst du das direkt hier lösen </v-card-text>
          <div class="d-flex flex-column justify-center align-center">
            <v-btn height="45px" width="45px" class="elevation-1" @click="$emit('showUpgrade')" fab>
              <v-icon color="white">mdi-file-document-plus</v-icon>
            </v-btn>
            <span class="my-1" style="color: white; line-height: 1.2; font-size: 13px">
              Vertrags <br />
              Upgrade
            </span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2" v-if="errorFetchingMeScans">
      <v-col cols="6" v-if="user && false">
        <user-checkin-card :user="user"></user-checkin-card>
      </v-col>
      <v-col cols="12" v-if="user">
        <user-weight-card @newTargetWeight="newTargetWeight" :user="user"></user-weight-card>
      </v-col>
    </v-row>

    <v-col cols="12" class="px-1" v-if="!errorFetchingMeScans">
      <!-- Chipgroup zum Wechseln der Ansichten -->
      <v-chip-group v-model="selectedType" mandatory row active-class="primary--text">
        <!-- Einzelmessung: Öffnet den normalen Datepicker -->
        <v-chip :value="0" @click="openMeasurementList()">
          {{ activeMeasurement && activeMeasurement.date && selectedType === 0 ? "Messung vom " + formatDate(activeMeasurement.date) : "Messungen" }}
        </v-chip>
        <v-chip :value="1">Statistiken</v-chip>
        <!-- Vergleich: Beim Klick wird automatisch der Datepicker im Mehrfachmodus geöffnet -->
        <v-chip :value="2" @click="openMeasurementList(true)">Vergleich</v-chip>
      </v-chip-group>

      <!-- Falls nicht im Vergleichsmodus, wird die zweite Chipgruppe (für die Ansicht) angezeigt -->
      <v-chip-group v-model="selectedIndex" mandatory row active-class="primary--text">
        <v-chip :value="0">Körperanalyse</v-chip>
        <v-chip :value="1">Herz</v-chip>
        <v-chip :value="2">Stoffwechsel</v-chip>
      </v-chip-group>

      <!-- Ansichten für Messungen/Statistiken -->
      <v-window v-model="selectedIndex" class="mt-4" touchless transition="slide-x-transition">
        <v-window-item :value="0">
          <user-mescan-data-statistics :measurements="measurements" v-if="selectedType === 1"></user-mescan-data-statistics>
          <user-mescan-data-comparison
            :measurement1="activeMeasurementComparison1"
            :measurement2="activeMeasurementComparison2"
            v-else-if="selectedType === 2 && activeMeasurementComparison2"></user-mescan-data-comparison>
          <user-mescan-data v-else-if="selectedType === 0" :response="activeMeasurement" />
        </v-window-item>
        <v-window-item :value="1">
          <user-mescan-heart-statistics :measurements="measurements" v-if="selectedType === 1"></user-mescan-heart-statistics>

          <user-mescan-heart-comparison
            :measurement1="activeMeasurementComparison1"
            :measurement2="activeMeasurementComparison2"
            v-else-if="selectedType === 2 && activeMeasurementComparison2"></user-mescan-heart-comparison>
          <user-mescan-heart v-else-if="selectedType === 0" :response="activeMeasurement" />
        </v-window-item>
        <v-window-item :value="2">
          <user-mescan-metabolism-statistics :measurements="measurements" v-if="selectedType === 1"></user-mescan-metabolism-statistics>
          <user-mescan-metabolism-comparison
            :measurement1="activeMeasurementComparison1"
            :measurement2="activeMeasurementComparison2"
            v-else-if="selectedType === 2 && activeMeasurementComparison2"></user-mescan-metabolism-comparison>
          <user-mescan-metabolism v-else-if="selectedType === 0" :response="activeMeasurement" />
        </v-window-item>
      </v-window>
    </v-col>

    <!-- Dialog zur Auswahl einer Messung -->
    <v-dialog v-model="showMeasurementList" max-width="400">
      <v-card>
        <!-- Toolbar als Kopfzeile mit Titel und Schließen-Button -->
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text"> Messung{{ comparisonMode ? "en" : "" }} auswählen </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeMeasurementList">
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0" style="max-height: 300px; overflow-y: auto">
          <v-list two-line>
            <!-- Subheader und Divider f¸r bessere Strukturierung -->
            <v-list-item
              v-for="measurement in sortedMeasurements"
              :key="measurement.date"
              @click="selectMeasurement(measurement)"
              :class="{ 'selected-item': isSelected(measurement) }"
              ripple>
              <!-- Avatar mit einem Kalender-Icon -->
              <v-list-item-avatar>
                <v-icon color="primary">mdi-calendar</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> {{ formatDate(measurement.date) }} </v-list-item-title>
                <v-list-item-subtitle v-if="measurement?.userInfo?.weight"> Gewicht: {{ measurement.userInfo.weight }} KG </v-list-item-subtitle>
              </v-list-item-content>
              <!-- Anzeige eines Check-Icons bei Selektion -->
              <v-list-item-action v-if="isSelected(measurement)">
                <v-icon color="primary">mdi-check-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/api"
import userService from "@/services/userService"
import UserMescanData from "@/views/user/components/UserMescanData.vue"
import UserMescanHeart from "@/views/user/components/UserMescanHeart.vue"
import UserMescanMetabolism from "@/views/user/components/UserMescanMetabolism.vue"
import UserWeightCard from "@/views/user/components/UserWeightCard.vue"
import UserMescanDataStatistics from "@/views/user/components/UserMescanDataStatistics.vue"
import UserMescanMetabolismStatistics from "@/views/user/components/UserMescanMetabolismStatistics.vue"
import UserMescanHeartStatistics from "@/views/user/components/UserMescanHeartStatistics.vue"
import UserMescanDataComparison from "../components/UserMescanDataComparison.vue"
import UserMescanHeartComparison from "../components/UserMescanHeartComparison.vue"
import UserMescanMetabolismComparison from "../components/UserMescanMetabolismComparison.vue"

export default {
  components: {
    UserMescanData,
    UserWeightCard,
    UserMescanHeart,
    UserMescanMetabolism,
    UserMescanDataStatistics,
    UserMescanMetabolismStatistics,
    UserMescanHeartStatistics,
    UserMescanDataComparison,
    UserMescanHeartComparison,
    UserMescanMetabolismComparison,
  },
  props: {
    user: {
      type: Object,
    },
  },
  async created() {
    try {
      this.errorFetchingMeScans = false
      let measurements = await api.fetchUserMescanData()
      measurements.forEach((measurement) => {
        for (let key in measurement.rating) {
          if (measurement.rating[key].hasOwnProperty("index")) {
            measurement.rating[key].index = measurement.rating[key].index - 1
          }
        }
      })
      this.measurements = measurements
      this.activeMeasurement = measurements.reduce((latest, current) => {
        return new Date(current.date) > new Date(latest.date) ? current : latest
      })
    } catch (e) {
      this.errorFetchingMeScans = true
    }
  },
  data() {
    return {
      errorFetchingMeScans: false,
      userService,
      measurements: [],
      activeMeasurement: {},
      selectedType: 0,
      selectedIndex: 0,
      showMeasurementList: false,
      comparisonMode: false,
      // im Vergleichsmodus eine Liste, in der die ausgewahlten Messungen zwischengespeichert werden
      selectedMeasurements: [],
      // die vorhandenen Messungen
      measurements: [],
      activeMeasurement: {},
      activeMeasurementComparison1: {},
      activeMeasurementComparison2: {},
      // Für Kalender-Funktionalitäten:
      currentWeekStart: this.$moment().startOf("week"),
    }
  },
  computed: {
    // Alle Messungstage im Format YYYY-MM-DD für den Datepicker
    measurementDates() {
      return this.measurements.map((measurement) => this.formatDate(measurement.date, "YYYY-MM-DD"))
    },
    weekDays() {
      let days = []
      for (let i = 0; i < 7; i++) {
        let date = this.$moment(this.currentWeekStart).add(i, "days")
        days.push({
          date: date.format("YYYY-MM-DD"),
          hasEvent: this.measurements.some((m) => m.date.startsWith(date.format("YYYY-MM-DD"))),
        })
      }
      return days
    },
    formattedDate() {
      return `${this.currentWeekStart.format("MMM D")} - ${this.$moment(this.currentWeekStart)
        .add(6, "days")
        .format("MMM D")}, ${this.currentWeekStart.format("YYYY")}`
    },
    sortedMeasurements() {
      return this.measurements.sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  methods: {
    isSelected(measurement) {
      if (!this.comparisonMode) return false
      return this.selectedMeasurements.some((m) => m.date === measurement.date)
    },
    openMeasurementList(isComparison = false) {
      this.comparisonMode = isComparison
      // Bei Einzelmodus: setze die selektierten Messungen zurück
      if (!isComparison) {
        this.selectedMeasurements = []
      }
      this.showMeasurementList = true
    },
    selectMeasurement(measurement) {
      if (this.comparisonMode) {
        // Toggle-Logik: Falls die Messung noch nicht ausgewahlt ist, hinzufuegen, ansonsten entfernen
        const index = this.selectedMeasurements.findIndex((m) => m.date === measurement.date)
        if (index === -1) {
          this.selectedMeasurements.push(measurement)
        } else {
          this.selectedMeasurements.splice(index, 1)
        }
        // Sobald zwei Messungen ausgewahlt wurden, setze den Vergleich und schliesse den Dialog
        if (this.selectedMeasurements.length === 2) {
          this.activeMeasurementComparison1 = this.selectedMeasurements[0]
          this.activeMeasurementComparison2 = this.selectedMeasurements[1]
          this.showMeasurementList = false
          // Optional: Auswahl zuruecksetzen
          this.selectedMeasurements = []
        }
      } else {
        // Einzelmodus: Setze die aktive Messung
        this.activeMeasurement = measurement
        this.showMeasurementList = false
      }
    },
    closeMeasurementList() {
      this.showMeasurementList = false
    },

    // Beispielhafte Formatierung des Datums
    formatDate(date, format = "DD.MM.YYYY") {
      return this.$moment(date).format(format)
    },

    newTargetWeight(val) {
      this.user.targetWeight = val
    },
    // Standardformatierung (Standard: DD.MM.YYYY)
    formatDate(date, format = "DD.MM.YYYY") {
      return this.$moment(date).format(format)
    },
  },
}
</script>

<style scoped>
.days-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.day-cell {
  height: 100px;
  position: relative;
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.day-number {
  font-size: 20px;
  font-weight: bold;
}

.event-dot {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
